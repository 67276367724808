'use client';

import { Box, styled, Typography } from '@mui/material';

export const StyledGridBox = styled(Box)(({ theme }) => {
  return {
    position: 'absolute',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    placeItems: 'center',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr',
    },
  };
});

export const SplashScreenBox = styled(Box)(({ theme }) => {
  const isLightMode = theme.palette.mode === 'light';
  return {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
    placeItems: 'center',
    zIndex: 1,
    backgroundColor: isLightMode
      ? theme.palette.grey['200']
      : theme.palette.grey['900'],
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      backgroundColor: 'transparent',
      top: 0,
      left: 0,
    },
  };
});

export const StyledImageBlock = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  bottom: '-50px',
  width: '100%',
  height: '60%',
  zIndex: -1,
  '.mobile-splash': {
    display: 'none',
  },
  '.splash': {
    display: 'block',
  },
  [theme.breakpoints.down('md')]: {
    right: 'initial',
    bottom: 'initial',
    top: 0,
    left: 0,
    width: '60%',
    '.mobile-splash': {
      display: 'block',
    },
    '.splash': {
      display: 'none',
    },
  },
}));

export const StyledLogoBlock = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  top: 'calc(30% - 100px)',
  width: '100%',
  height: '200px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const StyledHeader = styled(Typography)(({ theme }) => {
  return {
    fontSize: theme.typography.pxToRem(24),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(20),
    },
  };
});

export const StyledTextCall = styled(Typography)(({ theme }) => {
  return {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  };
});

export const StyledLoginFormBox = styled(Box)(({ theme }) => {
  return {
    zIndex: 2,
    maxWidth: theme.spacing(38),
    backgroundColor: 'transparent',
    '.mobile-logo': {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      position: 'initial',
      width: '90%',
      margin: '0 auto',
      '.mobile-logo': {
        display: 'block',
        margin: '0 auto',
        marginBottom: theme.spacing(5),
      },
    },
  };
});

export const StyledLoginForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  width: '100%',
});

export const StyledThemeButtonBox = styled(Box)(() => {
  return {
    zIndex: 9,
    position: 'absolute',
    right: '1rem',
    top: '1rem',
  };
});

export const StyledLoadingBox = styled(Box)(({ theme }) => {
  return {
    zIndex: 9999999,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 3,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor:
      theme.palette.mode === 'light'
        ? 'rgba(255, 255, 255, 0.6)'
        : 'rgba(30, 30, 30, 0.6)',
  };
});
