'use client';

import { type Dispatch, type SetStateAction } from 'react';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@mui/icons-material/';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  MenuProps,
  Select,
  type SelectChangeEvent,
} from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PLATFORMS = [
  { id: '0', name: 'APP' },
  { id: '1', name: 'WEB' },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MENU_PROPS: Partial<MenuProps> = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 9.8 + ITEM_PADDING_TOP,
    },
  },
};

export function PlatformSelect({
  selectedPlatforms,
  setter,
  id,
  label,
  fullWidth = true,
  disabled,
}: {
  selectedPlatforms: string[];
  setter: Dispatch<SetStateAction<string[]>>;
  id?: string;
  label?: string;
  fullWidth?: boolean;
  disabled?: boolean;
}) {
  const handleChange = ({ target }: SelectChangeEvent<string[]>) => {
    const { value } = target;
    setter(typeof value === 'string' ? value.split(',') : value);
  };

  const labelSelect = label ?? 'Plataforma';
  const labelId = `platform-select_${id ?? 'label'}`;

  return (
    <FormControl fullWidth={fullWidth} variant="filled" disabled={disabled}>
      <InputLabel id={labelId}>{labelSelect}</InputLabel>
      <Select
        sx={{ borderRadius: 0 }}
        multiple
        label={labelSelect}
        placeholder={labelSelect}
        labelId={labelId}
        id={id ?? 'platform-select'}
        onChange={handleChange}
        value={selectedPlatforms}
        renderValue={selected =>
          selected
            .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
            .map(
              sName => PLATFORMS.find(platform => platform.name === sName)?.name
            )
            .join(', ')
        }
        MenuProps={MENU_PROPS}
      >
        {PLATFORMS.map(platform => (
          <MenuItem key={platform.name} value={platform.name}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selectedPlatforms.indexOf(platform.name) > -1}
            />
            <ListItemText primary={platform.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
