import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { serviceApi } from '~/features';
import { ReportsFilterDto } from '~/types';

export const DOWNLOAD_REPORTS_FILTER_MUTATION_KEY =
  'DOWNLOAD_REPORTS_FILTER_MUTATION';

async function exportExcel(params: ReportsFilterDto) {
  return serviceApi.get<Blob>('/Report/Filter-Export', {
    params,
    responseType: 'blob',
  });
}

export function useDownloadReportsFilter(
  onSuccess?: (
    data: AxiosResponse<Blob, unknown>,
    variables: ReportsFilterDto,
    context: ReportsFilterDto
  ) => unknown,
  onError?: (
    error: Error,
    variables: ReportsFilterDto,
    context: ReportsFilterDto | undefined
  ) => unknown
) {
  return useMutation({
    mutationKey: [DOWNLOAD_REPORTS_FILTER_MUTATION_KEY],
    mutationFn: async (params: ReportsFilterDto) => exportExcel(params),
    onSuccess,
    onError,
  });
}
