export function parseDate(date: string) {
  if (!date) return null;

  const splitDateTime = date.split(' ');
  const splitDate = splitDateTime[0].split('/');
  const splitTime = splitDateTime[1].split(':');

  const month = Number(splitDate[1]);

  const dateISO = `${splitDate[2]}-${String(month).length === 1 ? `0${month}` : month}-${splitDate[0]}`;
  const timeISO = `${splitTime[0]}:${splitTime[1]}:${splitTime[2]}`;

  const dateTimeString = `${dateISO}T${timeISO}+00:00`;

  try {
    return new Date(dateTimeString);
  } catch (e) {
    console.error('Error parsing date', date, e);
    return null;
  }
}
