'use client';

import { Box, styled, Tab, Typography } from '@mui/material';

export const StyledTabItem = styled(Tab)({
  textTransform: 'none',
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: '190px',
});

export const StyledCampaignForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  paddingTop: theme.spacing(4),
}));

export const StyledCampaignFormSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.3rem',
  fontWeight: '500',
  color: theme.palette.primary.main,
}));

export const StyledCampaignFormRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}));

export const StyledManagementUserFormRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: 16,
  gap: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}));
export const StyledTypeSelectFormRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}));

export const StyledCampaignFormLabelTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: '500',
  color: theme.palette.primary.main,
}));

export const StyledSelectCampaignTypeBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  flexWrap: 'wrap',
}));
