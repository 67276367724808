/* eslint-disable no-param-reassign */
import type { Session } from 'next-auth';
import { getSession, signIn } from 'next-auth/react';
import axios, {
  type AxiosError,
  type CreateAxiosDefaults,
  isAxiosError,
} from 'axios';
import { isPast } from 'date-fns';
import { jwtDecode } from 'jwt-decode';

import { isClient } from '~/shared';
import { DecodedMartinsToken, DecodedPlatformToken } from '~/types';

export type FailedRequest = {
  onSuccess: (token?: string) => void;
  onFailure: (error: AxiosError) => void;
};

const MAX_RETRY_COUNT = 2;

class ApiBuilder {
  private static instance: ApiBuilder;

  private session: Session | null = null;

  private isRefreshing = false;

  private failedRequestsQueue: FailedRequest[] = [];

  private retryCount = 0;

  private constructor() {
    if (isClient()) {
      getSession().then(session => {
        this.session = session;
      });
    }
  }

  public static getInstance(): ApiBuilder {
    if (!ApiBuilder.instance) {
      ApiBuilder.instance = new ApiBuilder();
    }
    return ApiBuilder.instance;
  }

  private getAuthToken() {
    return this.session ? this.session.t.st : null;
  }

  private getTokenExpiration() {
    const tokens = this.session?.t;
    if (!tokens) return null;
    return {
      mt: tokens.mt
        ? new Date(jwtDecode<DecodedMartinsToken>(tokens.mt).exp * 1000)
        : null,
      st: new Date(jwtDecode<DecodedPlatformToken>(tokens.st).exp * 1000),
      // rt: new Date(jwtDecode<DecodedPlatformToken>(tokens.rt).exp * 1000),
    };
  }

  public mount(config: CreateAxiosDefaults = {}) {
    const api = axios.create({
      ...config,
      withCredentials: true,
      paramsSerializer: { indexes: null },
    });

    // REQUEST INTERCEPTOR
    api.interceptors.request.use(async rc => {
      /**
       * TODO: SERVER CALLS INTERCEPTOR
       * if we start using server side calls, we'll need to handle request headers if isServer() too
       */

      // CLIENT AUTHORIZATION HEADERS
      if (isClient()) {
        const exp = this.getTokenExpiration();
        const isSessionInvalid =
          !this.session ||
          isPast(new Date(this.session.expires)) ||
          (exp && isPast(exp.st));

        if (isSessionInvalid) {
          this.session = (await getSession()) ?? this.session;
        }

        if (this.session && !rc.headers?.getAuthorization()) {
          rc.headers.setAuthorization(`Bearer ${this.getAuthToken()}`);
        }
      }

      return rc;
    });

    // RESPONSE INTERCEPTOR
    api.interceptors.response.use(
      response => response,
      async (error: AxiosError) => {
        // UNAUTHORIZED - 401
        if (error.response?.status === 401) {
          // Refresh session first
          this.session = await getSession();

          // Original request configuration
          const ogc = error.config;

          // Tokens expirations
          const exp = this.getTokenExpiration();

          // session does not have tokens
          if (!exp) return Promise.reject(error);

          /**
           * TODO: SERVER CALLS INTERCEPTOR
           * if we start using server side calls, we'll need to handle tokens expiration if isServer() too
           */

          // CLIENT INTERCEPTOR FOR EXPIRED TOKENS
          if (isClient() && this.session) {
            // Refresh Token Expired or Martins Token Expired
            if (isPast(exp.st)) {
              // REFRESH TOKEN WILL FAIL
              return Promise.reject(error);
            }

            // Service Token Expired
            if (isPast(exp.st)) {
              // REFRESH TOKEN
              this.isRefreshing = true;

              try {
                const refresh = await signIn('refresh-token', {
                  token: this.session.t.mt,
                  serviceToken: this.session.t.st,
                  refreshToken: this.session.t.rt,
                  registration: this.session.user?.registration,
                  redirect: false,
                });

                if (refresh && !refresh.ok) {
                  throw new Error('Refresh token falhou ao renovar.');
                }

                this.retryCount = 0;

                this.session = await getSession();

                ogc?.headers.setAuthorization('');

                this.failedRequestsQueue.forEach(request =>
                  request.onSuccess()
                );

                this.failedRequestsQueue = [];
              } catch (err: unknown) {
                if (isAxiosError(err)) {
                  this.retryCount += 1;
                  this.failedRequestsQueue.forEach(request =>
                    request.onFailure(err)
                  );
                }
                this.failedRequestsQueue = [];
              } finally {
                this.isRefreshing = false;
              }
            }
          }

          // REACHED MAX RETRY - REFRESH TOKEN FAILED
          if (this.retryCount >= MAX_RETRY_COUNT && isClient()) {
            return Promise.reject(error);
          }

          return new Promise((resolve, reject) => {
            if (isClient()) {
              this.failedRequestsQueue.push({
                onSuccess() {
                  if (!ogc) {
                    const msg = 'Configuração inicial de API não encontrado.';
                    reject(new Error(msg));
                    return;
                  }

                  resolve(api(ogc));
                },
                onFailure(err) {
                  reject(err);
                },
              });
            }
          });

          //
        } // END OF 401

        // Not a 401 error
        return Promise.reject(error);
      }
    );

    return api;
  }
}

export const nextApi = ApiBuilder.getInstance().mount({
  baseURL: `${process.env.NEXT_PUBLIC_NEXT_API_URL}`,
});

export const serviceApi = ApiBuilder.getInstance().mount({
  baseURL: `${process.env.NEXT_PUBLIC_SERVICE_API_URL}/api/v1`,
});
