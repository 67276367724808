import { type ComponentProps, type ReactNode } from 'react';
import { TrendingDown, TrendingUp } from '@mui/icons-material';
import { Box, Skeleton, Typography } from '@mui/material';

import { StyledCard, StyledIconCard } from './elements';

export type CardProps = {
  icon?: ReactNode;
  variation?: number;
  variationText?: string;
  // variant: 'primary' | 'regular';
  iconColor?: string;
  iconBackgroundColor?: string;
  title: string | string[];
  value: string | string[];
  isLoading?: boolean;
  titleColor?: string;
} & Omit<ComponentProps<typeof StyledCard>, 'title'>;

function CardTitleValue({
  title,
  value,
  color,
}: {
  title: string;
  value: string;
  color: string;
}) {
  return (
    <Box>
      <Typography color={color} sx={{ fontWeight: 400, mb: 1 }}>
        {title}
      </Typography>
      <Typography variant="h5" color={color} sx={{ fontWeight: 500 }}>
        {value}
      </Typography>
    </Box>
  );
}

export function Card({
  icon,
  title,
  value,
  variation,
  variationText,
  // variant,
  iconColor = '#606060',
  iconBackgroundColor = '#ECECEC',
  isLoading,
  titleColor,
  ...props
}: CardProps) {
  const isPositive = variation ? variation >= 0 : false;

  return (
    <>
      {isLoading && (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          sx={{ minHeight: '110px', borderRadius: '8px' }}
          animation="wave"
        />
      )}
      {!isLoading && (
        <StyledCard {...props}>
          {icon && (
            <StyledIconCard
              sx={{
                color: iconColor,
                backgroundColor: iconBackgroundColor,
              }}
            >
              {icon}
            </StyledIconCard>
          )}

          <Box
            sx={theme => ({
              display: 'flex',
              gap: 2,
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
            })}
          >
            {typeof title === 'string' && typeof value === 'string' && (
              <CardTitleValue
                title={title}
                value={value}
                color={titleColor ?? 'palette.text.primary'}
              />
            )}

            {Array.isArray(title) &&
              Array.isArray(value) &&
              title.map((t, index) => (
                <Box
                  key={t}
                  sx={{ display: 'flex', gap: 2, alignItems: 'center' }}
                >
                  <CardTitleValue
                    title={t}
                    value={value[index]}
                    color={titleColor ?? '#4D4A65'}
                  />
                  {index + 1 < title.length && (
                    <Box
                      sx={theme => ({
                        mx: 2,
                        width: '1px',
                        height: '35px',
                        backgroundColor: '#869DF1',
                        [theme.breakpoints.down('md')]: {
                          display: 'none',
                        },
                      })}
                    />
                  )}
                </Box>
              ))}
          </Box>

          {variation && variationText && (
            <Box
              sx={{ display: 'flex', gap: 0.5, alignItems: 'center', mt: 1 }}
            >
              {isPositive ? (
                <TrendingUp sx={{ color: '#1AB90E' }} />
              ) : (
                <TrendingDown sx={{ color: '#E10770' }} />
              )}
              <Typography
                variant="body2"
                component="p"
                sx={{ color: isPositive ? '#1AB90E' : '#E10770', mr: 0.5 }}
              >
                {`${isPositive ? '+' : ''}${variation}%`}
              </Typography>
              <Typography variant="body1" color="#767895">
                {variationText}
              </Typography>
            </Box>
          )}
        </StyledCard>
      )}
    </>
  );
}
