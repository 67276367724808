export function addToQueryString(
  name: string,
  value: string,
  currentParams: string
) {
  const params = new URLSearchParams(currentParams);
  params.set(name, value);
  return params.toString();
}

export function removeFromQueryString(name: string, currentParams: string) {
  const params = new URLSearchParams(currentParams);
  params.delete(name);
  return params.toString();
}
