'use client';

import { Search } from '@mui/icons-material';
import { TextField } from '@mui/material';

import { StyledSearchBoxContainer, StyledSearchBoxIcon } from './elements';

type SearchBoxProps = {
  id?: string;
  label?: string;
  value: string;
  onChange: (value: string) => void;
  isLoading?: boolean;
  fullWidth?: boolean;
};

export function SearchBox({
  id,
  label,
  value,
  onChange,
  isLoading,
  fullWidth,
}: SearchBoxProps) {
  return (
    <StyledSearchBoxContainer isLoading={isLoading} fullWidth={fullWidth}>
      <StyledSearchBoxIcon>
        <Search sx={{ width: '28px', height: '28px' }} />
      </StyledSearchBoxIcon>
      <TextField
        autoComplete="off"
        id={id}
        label={label}
        type="text"
        variant="filled"
        sx={{ width: '100%' }}
        value={value}
        onChange={e => onChange(e.target.value)}
      />
    </StyledSearchBoxContainer>
  );
}
