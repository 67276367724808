import { useRouter } from 'next/navigation';
import { TableCell, TableRow, Typography } from '@mui/material';

import {
  PlatformBadge,
  ProcessedDate,
  ProcessedName,
  StyledIdTableCell,
} from '~/shared';
import type { CampaignStatus } from '~/types';

import { UseGetCampaignsReturnList } from '../../hooks';
import { StatusSelectBadge } from '../StatusSelectBadge';

export type CampaignRowProps = {
  campaign: UseGetCampaignsReturnList[number];
};

export function CampaignRow({ campaign }: CampaignRowProps) {
  const router = useRouter();

  return (
    <TableRow
      key={campaign.name}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          '.MuiTableCell-body': {
            backgroundColor: theme => theme.palette.grey[100],
          },
        },
        '.MuiTableCell-body': {
          backgroundColor: 'background.paper',
        },
      }}
    >
      <StyledIdTableCell
        component="th"
        scope="row"
        align="center"
        onClick={() => router.push(`/campanhas/${campaign.id}`)}
      >
        {campaign.id}
      </StyledIdTableCell>
      <TableCell
        component="th"
        scope="row"
        sx={{
          position: 'sticky',
          left: 0,
        }}
        onClick={() => router.push(`/campanhas/${campaign.id}`)}
      >
        <ProcessedName
          name={campaign.name}
          advertiserName={campaign.advertiser?.name}
        />
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        onClick={() => router.push(`/campanhas/${campaign.id}`)}
      >
        <PlatformBadge isApp={campaign.isApp} isWeb={campaign.isWeb} />
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        align="center"
        onClick={() => router.push(`/campanhas/${campaign.id}`)}
      >
        {campaign.startDate ? <ProcessedDate date={campaign.startDate} /> : '-'}
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        align="center"
        onClick={() => router.push(`/campanhas/${campaign.id}`)}
      >
        {campaign.endDate ? <ProcessedDate date={campaign.endDate} /> : '-'}
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        align="center"
        sx={{ pointerEvents: 'none' }}
      >
        {campaign.status && (
          <StatusSelectBadge
            campaignId={campaign.id}
            campaignStatus={
              campaign.status.toUpperCase() as `${CampaignStatus}`
            }
          />
        )}
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        align="center"
        onClick={() => router.push(`/campanhas/${campaign.id}`)}
      >
        <Typography>--</Typography>
      </TableCell>
    </TableRow>
  );
}
