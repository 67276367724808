import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import sanitize from 'sanitize-html';

import { serviceApi } from '~/features';
import type { UpdateAdvertiserDto, UpdateAdvertiserModelView } from '~/types';

export const PATCH_ADVERTISER_MUTATION_KEY = 'PATCH_ADVERTISER_MUTATION';

async function updateAdvertiser(body: UpdateAdvertiserDto) {
  return serviceApi.patch<UpdateAdvertiserModelView>(
    '/Anunciante',
    { ...body, ...(body.name ? { name: sanitize(body.name) } : {}) },
    { headers: { 'Content-Type': 'application/json' } }
  );
}

export function useUpdateAdvertiser(
  onSuccess?: (
    data: AxiosResponse<UpdateAdvertiserModelView, unknown>,
    variables: UpdateAdvertiserDto,
    context: unknown
  ) => unknown,
  onError?: (
    error: Error,
    variables: UpdateAdvertiserDto,
    context: unknown
  ) => unknown
) {
  return useMutation({
    mutationKey: [PATCH_ADVERTISER_MUTATION_KEY],
    mutationFn: async (body: UpdateAdvertiserDto) => updateAdvertiser(body),
    onSuccess,
    onError,
  });
}
