import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  TextField,
} from '@mui/material';

import { CheckboxCheckedIcon, CheckboxIcon } from '~/shared';
import type { CampaignSegmentOption, CampaignSegmentsModelView } from '~/types';

import { useGetSegments } from '../hooks';

export type SelectStateProps = {
  id: string;
  label: string;
  value?: CampaignSegmentOption[];
  onChange: (value: CampaignSegmentOption[]) => void;
  segment: keyof CampaignSegmentsModelView;
  multiple?: boolean;
  disabled?: boolean;
};

export function SelectState({
  id,
  value = [],
  onChange,
  label,
  segment,
  disabled,
}: SelectStateProps) {
  const { isLoading, isError, data } = useGetSegments();

  // To create a filtered items without 'cidades'
  const filteredData =
    segment === 'estado'
      ? {
          ...data,
          estado: data?.estado?.map(estado => ({
            ...estado,
            cidades: [],
          })),
        }
      : data;

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      disabled={disabled || isLoading || isError}
      sx={{ flexGrow: 1, flexBasis: '100%' }}
      id={`segment-selections${id ? `_${id}` : ''}`}
      options={(filteredData?.[segment] ?? []) as CampaignSegmentOption[]}
      getOptionLabel={option => option.name}
      // eslint-disable-next-line @typescript-eslint/no-shadow
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      renderOption={(props, option) => {
        const isChecked = value.some(val => val.id === option.id);
        return (
          <li {...props}>
            <Checkbox
              icon={CheckboxIcon}
              checkedIcon={CheckboxCheckedIcon}
              style={{ marginRight: 8 }}
              checked={isChecked}
            />
            {option.name}
          </li>
        );
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant="filled"
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress
                    color="inherit"
                    size={25}
                    sx={{ mt: '-18px' }}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
