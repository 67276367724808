import {
  CircularProgress,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { StyledHeaderTableCell, StyledTable } from '~/shared';

import { useGetDashboard } from '../hooks';

import { DashListRow } from './DashListRow';

export type BetterCampaignListProps = {
  getDashboardQuery: ReturnType<typeof useGetDashboard>;
};

export function DashList({ getDashboardQuery }: BetterCampaignListProps) {
  const { data, isLoading, isError, error } = getDashboardQuery;

  return (
    <StyledTable aria-label="Tabela de Banners">
      <TableHead>
        <TableRow>
          <StyledHeaderTableCell sx={{ width: '60px' }} align="center">
            ID
          </StyledHeaderTableCell>
          <StyledHeaderTableCell
            sx={{
              minWidth: '200px',
              position: 'sticky',
              left: 0,
              backgroundColor: 'background.paper',
            }}
          >
            Anunciante / Campanha
          </StyledHeaderTableCell>
          <StyledHeaderTableCell align="center">
            Impressões
          </StyledHeaderTableCell>
          <StyledHeaderTableCell align="center">Cliques</StyledHeaderTableCell>
          <StyledHeaderTableCell align="center">CTR</StyledHeaderTableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {isLoading && (
          <TableRow>
            <TableCell component="th" scope="row" colSpan={7} align="center">
              <CircularProgress size={15} /> Carregando
            </TableCell>
          </TableRow>
        )}

        {!isLoading && !isError && data?.bestCampaigns?.length === 0 && (
          <TableRow>
            <TableCell colSpan={7} align="center">
              Sem resultados
            </TableCell>
          </TableRow>
        )}

        {isError && (
          <TableRow>
            <TableCell
              sx={{ color: theme => theme.palette.error.light }}
              colSpan={7}
              align="center"
            >
              <Typography>Ocorreu um erro: {error?.name}</Typography>
              <Typography>{error?.message}</Typography>
            </TableCell>
          </TableRow>
        )}

        {!isLoading &&
          !isError &&
          data?.bestCampaigns?.map(campaign => {
            return <DashListRow key={campaign.id} campaign={campaign} />;
          })}
      </TableBody>
    </StyledTable>
  );
}
