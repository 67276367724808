import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { serviceApi } from '~/features';

export const DOWNLOAD_CAMPAIGN_EXCEL_MUTATION_KEY =
  'DOWNLOAD_CAMPAIGN_EXCEL_MUTATION';

async function exportExcel(campaignId: string) {
  return serviceApi.get<Blob>('/CalculoCampanha/export-excel', {
    params: { campaignId },
    responseType: 'blob',
  });
}

export function useDownloadCampaignExcel(
  onSuccess?: (
    data: AxiosResponse<Blob, unknown>,
    variables: string,
    context: unknown
  ) => unknown,
  onError?: (error: Error, variables: string, context: unknown) => unknown
) {
  return useMutation({
    mutationKey: [DOWNLOAD_CAMPAIGN_EXCEL_MUTATION_KEY],
    mutationFn: async (campaignId: string) => exportExcel(campaignId),
    onSuccess,
    onError,
  });
}
