'use client';

import { useState } from 'react';
import { useDebounce } from 'react-use';
import Image from 'next/image';
import { Box } from '@mui/material';
import { isURL } from 'validator';

import { CurrencyTextField } from '~/shared';
import type { BannersKey, CampaignBanner } from '~/types';

import { UseUpdateCampaignReturn } from '../../hooks';

export function BannerManager({
  campaignId,
  bannerPosition,
  campaignBanner,
  updateCampaignQuery,
  bannerType,
  bannerWidth,
  bannerHeight,
}: {
  campaignId: string;
  bannerPosition: BannersKey;
  campaignBanner?: CampaignBanner;
  updateCampaignQuery: UseUpdateCampaignReturn;
  bannerType: 'web' | 'app';
  bannerWidth: number;
  bannerHeight: number;
}) {
  const [bannerValue, setBannerValue] = useState<number | undefined>(
    campaignBanner?.investmentValue
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bannerLink, setBannerLink] = useState<string | undefined>(
    campaignBanner?.linkImage
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bannerLinkError, setBannerLinkError] = useState<string | null>(null);

  useDebounce(
    () => {
      if (
        (campaignBanner && bannerValue !== campaignBanner.investmentValue) ||
        (!campaignBanner && bannerValue)
      ) {
        updateCampaignQuery.mutate({
          id: campaignId,
          [bannerType === 'app' ? 'appBanners' : 'webBanners']: [
            {
              key: bannerPosition.key,
              investmentValue: bannerValue,
            },
          ],
        });
      }
    },
    500,
    [bannerValue]
  );

  useDebounce(
    () => {
      if (
        (campaignBanner && bannerLink !== campaignBanner.linkImage) ||
        (!campaignBanner && bannerLink)
      ) {
        if (bannerLink && isURL(bannerLink)) {
          updateCampaignQuery.mutate({
            id: campaignId,
            [bannerType === 'app' ? 'appBanners' : 'webBanners']: [
              {
                key: bannerPosition.key,
                linkImage: bannerLink,
              },
            ],
          });
        } else if (bannerLink === '' && campaignBanner?.linkImage) {
          updateCampaignQuery.mutate({
            id: campaignId,
            [bannerType === 'app' ? 'appBanners' : 'webBanners']: [
              {
                key: bannerPosition.key,
                linkImage: '',
              },
            ],
          });
        } else if (bannerLink === '') {
          setBannerLinkError('');
        } else {
          setBannerLinkError('Informe uma URL válida');
        }
      }
    },
    500,
    [bannerLink]
  );

  return (
    <Box sx={{ mt: -2 }}>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          backgroundColor: theme =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          minHeight: 150,
          mb: 2,
        }}
      >
        {campaignBanner?.image && (
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: `${bannerHeight}px`,
              maxWidth: `${bannerWidth}px`,
              margin: '0 auto',
              overflowX: 'auto',
              overflowY: 'hidden',
            }}
          >
            <Image
              alt="Imagem do banner"
              src={campaignBanner.image}
              width={bannerWidth}
              height={bannerHeight}
            />
          </Box>
        )}
      </Box>
      <Box sx={{ flexDirection: 'column', width: '100%' }}>
        {/* <TextField
          value={bannerLink}
          onChange={event => setBannerLink(event.target.value)}
          variant="filled"
          fullWidth
          label="Link único para este formato"
          placeholder="ex: https://www.google.com"
          error={!!bannerLinkError}
          helperText={bannerLinkError ?? 'Esse link irá sobrepor o link geral'}
        /> */}

        <CurrencyTextField
          value={bannerValue}
          onChange={event => setBannerValue(Number(event.target.value))}
          variant="filled"
          label="Valor do banner"
          sx={{
            width: '100%',
            maxWidth: '100%',
          }}
        />
      </Box>
    </Box>
  );
}
