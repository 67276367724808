'use client';

import Link from 'next/link';
import { ArrowBack } from '@mui/icons-material';
import { Box, CircularProgress, IconButton } from '@mui/material';

import {
  StyledContentTitleBox,
  StyledTitle,
  StyledTitleContent,
} from './elements';

type ContentTitleProps = {
  title: string;
  actions?: React.ReactNode;
  isFetching?: boolean;
  returnLink?: string;
  returnAction?: () => void;
};

function Returner({ onClick, href }: { onClick?: () => void; href?: string }) {
  if (href) {
    return (
      <Link href={href} passHref>
        <IconButton onClick={onClick}>
          <ArrowBack />
        </IconButton>
      </Link>
    );
  }

  return (
    <IconButton onClick={onClick}>
      <ArrowBack />
    </IconButton>
  );
}

export function ContentTitle({
  title,
  actions,
  isFetching,
  returnLink,
  returnAction,
}: ContentTitleProps) {
  return (
    <StyledContentTitleBox>
      <StyledTitleContent>
        {returnLink && <Returner href={returnLink} />}
        {returnAction && <Returner onClick={returnAction} />}
        <StyledTitle variant="h1">{title}</StyledTitle>
        {isFetching && <CircularProgress size={28} />}
      </StyledTitleContent>
      <Box
        sx={theme => ({
          display: 'flex',
          gap: 2,
          [theme.breakpoints.down('md')]: { flex: 1, width: '100%' },
          [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
        })}
      >
        {actions}
      </Box>
    </StyledContentTitleBox>
  );
}
