import { CustomIconsProps } from './types';

export function DeleteIcon({
  size = 24,
  color = 'currentColor',
}: CustomIconsProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.33333 15.5C3.90556 15.5 3.53935 15.3477 3.23472 15.0431C2.93009 14.7384 2.77778 14.3722 2.77778 13.9444V3.83333H2V2.27778H5.88889V1.5H10.5556V2.27778H14.4444V3.83333H13.6667V13.9444C13.6667 14.3722 13.5144 14.7384 13.2097 15.0431C12.9051 15.3477 12.5389 15.5 12.1111 15.5H4.33333ZM12.1111 3.83333H4.33333V13.9444H12.1111V3.83333ZM5.88889 12.3889H7.44444V5.38889H5.88889V12.3889ZM9 12.3889H10.5556V5.38889H9V12.3889Z"
        fill={color}
      />
    </svg>
  );
}
