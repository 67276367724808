import { Fragment, useState } from 'react';
import { useDebounce } from 'react-use';
import { CameraAltOutlined } from '@mui/icons-material';
import { Box, FormControlLabel } from '@mui/material';

import { DefaultSwitch } from '~/shared';
import type { BannersKey, CampaignBanner } from '~/types';

import { StyledCampaignFormLabelTitle } from '../../components/elements';
import { EditImageModal } from '../../dialogs';
import { UseUpdateCampaignReturn } from '../../hooks';

import { BannerController } from './BannerController';
import { BannerManager } from './BannerManager';

export type BannerRowProps = {
  campaignId: string;
  bannerPosition: BannersKey;
  campaignBanner?: CampaignBanner;
  updateCampaignQuery: UseUpdateCampaignReturn;
  bannerType: 'web' | 'app';
};

export function BannerRow({
  campaignId,
  bannerPosition,
  campaignBanner,
  updateCampaignQuery,
  bannerType,
}: BannerRowProps) {
  const [bannerActive, setBannerActive] = useState<boolean>(
    campaignBanner?.active || false
  );

  const [editImage, setEditImage] = useState(false);

  useDebounce(
    () => {
      if (
        (campaignBanner && bannerActive !== campaignBanner.active) ||
        (!campaignBanner && bannerActive)
      ) {
        updateCampaignQuery.mutate({
          id: campaignId,
          [bannerType === 'app' ? 'appBanners' : 'webBanners']: [
            { key: bannerPosition.key, active: bannerActive },
          ],
        });
      }
    },
    500,
    [bannerActive]
  );

  const matchSize = bannerPosition?.name.match(/\((\d+)x(\d+)\)/);
  const [bannerWidth, bannerHeight] = matchSize?.slice(1)?.map(Number) ?? [
    0, 0,
  ];

  return (
    <Fragment key={bannerPosition.name}>
      <Box
        key={bannerPosition.name}
        sx={theme => ({
          display: 'flex',
          justifyContent: 'space-between',
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: 2,
          },
        })}
      >
        <FormControlLabel
          id={`banner-position-${bannerPosition.key}`}
          control={<DefaultSwitch />}
          checked={bannerActive}
          onChange={(_, checked) => setBannerActive(checked)}
          label={
            <StyledCampaignFormLabelTitle>
              {bannerPosition.name}
              {campaignBanner?.image && (
                <CameraAltOutlined fontSize="small" sx={{ ml: 1 }} />
              )}
            </StyledCampaignFormLabelTitle>
          }
        />

        {bannerActive && (
          <BannerController
            imageUrl={campaignBanner?.image}
            editImageAction={() => setEditImage(true)}
          />
        )}
      </Box>

      {bannerActive && (
        <BannerManager
          campaignId={campaignId}
          bannerType={bannerType}
          campaignBanner={campaignBanner}
          bannerPosition={bannerPosition}
          updateCampaignQuery={updateCampaignQuery}
          bannerWidth={bannerWidth}
          bannerHeight={bannerHeight}
        />
      )}

      {editImage && (
        <EditImageModal
          bannerType={bannerType}
          campaignId={campaignId}
          bannerKey={bannerPosition.key}
          imageUrl={campaignBanner?.image}
          onClose={() => setEditImage(false)}
          bannerWidth={bannerWidth}
          bannerHeight={bannerHeight}
          updateCampaignQuery={updateCampaignQuery}
        />
      )}
    </Fragment>
  );
}
