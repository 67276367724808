'use client';

import { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';

import { Dialog, GreenCheck } from '~/shared';

import { StyledAdvertisersForm } from '../components/elements';
import { useCreateAdvertiser } from '../hooks';

export function CreateAdvertiserDialog({
  onClose,
  refetchList,
  open,
}: {
  open: boolean;
  onClose: () => void;
  refetchList: () => void;
}) {
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [advertiserName, setAdvertiserName] = useState('');
  const useCreateAdvertiserMutation = useCreateAdvertiser(() => {
    refetchList();
    setSuccessSubmit(true);
  });

  function handleClose() {
    if (useCreateAdvertiserMutation.isPending) return;
    setAdvertiserName('');
    onClose();
    useCreateAdvertiserMutation.reset();
    setTimeout(() => setSuccessSubmit(false), 300);
  }

  function handleSubmit() {
    if (advertiserName === '') return;
    useCreateAdvertiserMutation.mutate({ name: advertiserName });
  }

  const dialogTitle = successSubmit
    ? 'Anunciante adicionado com sucesso'
    : 'Novo Anunciante';

  const dialogIcon = successSubmit ? <GreenCheck size={70} /> : undefined;

  return (
    <Dialog
      title={dialogTitle}
      withIcon={dialogIcon}
      open={open}
      onClose={handleClose}
      onCloseTransitionEnded={() => {
        setSuccessSubmit(false);
        useCreateAdvertiserMutation.reset();
      }}
    >
      {!successSubmit && (
        <StyledAdvertisersForm>
          <TextField
            id="advertiser-name"
            label="Nome do anunciante"
            fullWidth
            autoComplete="off"
            type="text"
            variant="filled"
            value={advertiserName}
            onChange={e => setAdvertiserName(e.target.value)}
            error={!!useCreateAdvertiserMutation.error}
            disabled={useCreateAdvertiserMutation.isPending}
          />
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <Button
              sx={{ px: 3, py: 1 }}
              size="small"
              fullWidth
              variant="outlined"
              onClick={() => handleClose()}
              disabled={useCreateAdvertiserMutation.isPending}
            >
              Voltar
            </Button>
            <Button
              disabled={
                !advertiserName || useCreateAdvertiserMutation.isPending
              }
              sx={{ px: 3, py: 1 }}
              size="small"
              fullWidth
              variant="contained"
              type="submit"
              onClick={e => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              Adicionar
            </Button>
          </Box>
          {useCreateAdvertiserMutation.isError && (
            <Typography
              sx={{
                mt: 2,
                fontSize: theme => theme.typography.pxToRem(16),
                color: theme => theme.palette.error.light,
              }}
            >
              {useCreateAdvertiserMutation.error?.message}
            </Typography>
          )}
        </StyledAdvertisersForm>
      )}
      {successSubmit && (
        <Button
          variant="outlined"
          size="small"
          sx={{ py: 2 }}
          fullWidth
          onClick={() => handleClose()}
        >
          Ver Anunciantes
        </Button>
      )}
    </Dialog>
  );
}
