import { useQuery } from '@tanstack/react-query';

import { serviceApi } from '~/features';
import { type CampaignResultsResumedModelView } from '~/types';

export const GET_CAMPAIGN_RESULTS_RESUMED_QUERY_KEY =
  'GET_CAMPAIGN_RESULTS_RESUMED_QUERY';

export async function getCampaignResultsResumed(id: string) {
  return serviceApi.get<CampaignResultsResumedModelView>(
    `/CalculoCampanha/calculo-resumido?CampaignId=${id}`
  );
}

export function useGetCampaignResultsResumed(id: string) {
  return useQuery({
    queryKey: [GET_CAMPAIGN_RESULTS_RESUMED_QUERY_KEY, id],
    queryFn: () => getCampaignResultsResumed(id),
    select: data => data.data,
  });
}
