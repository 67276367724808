import { Rubik } from 'next/font/google';
import type { ThemeOptions } from '@mui/material';

const roboto = Rubik({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
});

export const commonTheme: ThemeOptions = {
  typography: {
    fontFamily: roboto.style.fontFamily,
  },
  uiSizes: {
    drawerWidth: 240,
    drawerWidthClosed: 75,
    headerHeight: 80,
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
          padding: 8,
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: '#6E7191',
          borderRadius: 4,
        },
      },
    },
  },
};
