'use client';

import Link from 'next/link';
// import { usePathname } from 'next/navigation';
import { ChevronRight } from '@mui/icons-material';
import { Breadcrumbs as DsBreadcrumbs } from '@mui/material';

import {
  StyledContextText,
  StyledItemText,
  StyledList,
  StyledListItem,
  StyledSeparator,
} from './elements';
import { useBreadcrumbs } from './provider';

export function Breadcrumbs() {
  const breadcrumbs = useBreadcrumbs();

  return (
    <DsBreadcrumbs
      data-testid="breadcrumbs-component"
      sx={theme => ({
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      })}
    >
      <StyledList>
        {(breadcrumbs.paths?.length ?? 0) < 3 && (
          <StyledListItem>
            <StyledContextText>Geral</StyledContextText>
            <StyledSeparator>
              <ChevronRight />
            </StyledSeparator>
          </StyledListItem>
        )}

        {breadcrumbs.paths?.map((path, index) => {
          return (
            <StyledListItem key={`${path}`}>
              {index === (breadcrumbs.paths?.length ?? 0) - 1 && (
                <StyledItemText>{path.label}</StyledItemText>
              )}
              {index < (breadcrumbs.paths?.length ?? 0) - 1 && (
                <>
                  <Link href={path.path}>
                    <StyledContextText
                      sx={theme => ({
                        [theme.breakpoints.down('md')]: {
                          display: 'none',
                        },
                        transition: theme.transitions.create(['color'], {
                          duration: theme.transitions.duration.shortest,
                          easing: theme.transitions.easing.easeInOut,
                        }),
                        '&:hover': {
                          color: '#333333',
                        },
                      })}
                      title={path.label}
                    >
                      {(breadcrumbs.paths?.length ?? 0) > 2 &&
                      (breadcrumbs.paths?.length ?? 0) - 2 > index
                        ? '...'
                        : path.label}
                    </StyledContextText>
                  </Link>
                  <StyledSeparator
                    sx={theme => ({
                      [theme.breakpoints.down('md')]: {
                        display: 'none',
                      },
                    })}
                  >
                    <ChevronRight />
                  </StyledSeparator>
                </>
              )}
            </StyledListItem>
          );
        })}

        {/* {pathNames.map((path, index) => {
          const href = `/${pathNames.slice(0, index + 1).join('/')}`;
          const itemLink =
            path[0].toUpperCase() +
            path.slice(1, path.length).split('-').join(' ');

          if (itemLink === 'Editar') return null;

          return (
            <StyledListItem key={`${path}`}>
              {index === pathNames.length - 1 && (
                <StyledItemText>{itemLink}</StyledItemText>
              )}
              {index < pathNames.length - 1 && (
                <>
                  <Link href={href}>
                    <StyledContextText
                      sx={{
                        transition: theme =>
                          theme.transitions.create(['color'], {
                            duration: theme.transitions.duration.shortest,
                            easing: theme.transitions.easing.easeInOut,
                          }),
                        '&:hover': {
                          color: '#333333',
                        },
                      }}
                    >
                      {itemLink}
                    </StyledContextText>
                  </Link>
                  <StyledSeparator>
                    <ChevronRight />
                  </StyledSeparator>
                </>
              )}
            </StyledListItem>
          );
        })} */}
      </StyledList>
    </DsBreadcrumbs>
  );
}

export * from './provider';
