'use client';

import { Box, ButtonBase, styled, type Theme } from '@mui/material';

import { CampaignStatus } from '~/types';

type StyledStatusBadgeProps = {
  variant: `${CampaignStatus}`;
  withBorder?: boolean;
  withSelector?: boolean;
};

const shouldForwardProp = (prop: PropertyKey) =>
  prop !== 'withBorder' && prop !== 'withSelector' && prop !== 'variant';

const styleBadge = ({
  theme,
  variant,
  withBorder,
  withSelector,
}: StyledStatusBadgeProps & { theme: Theme }) => {
  let backgroundColor = '#ECECEC';
  let color = '#BBC0CC';
  let border = '1px solid transparent';

  switch (variant) {
    case CampaignStatus.ACTIVE:
      backgroundColor = '#D8F0E0';
      color = '#41B970';
      border = withBorder ? '1px solid #41B970' : '1px solid transparent';
      break;
    case CampaignStatus.DRAFT:
      backgroundColor = '#E2EFFB';
      color = '#2D52DA';
      border = withBorder ? '1px solid #2D52DA' : '1px solid transparent';
      break;
    case CampaignStatus.REVIEW:
      backgroundColor = '#FDF1D6';
      color = '#FAC338';
      border = withBorder ? '1px solid #FAC338' : '1px solid transparent';
      break;
    case CampaignStatus.ATTENTION:
      backgroundColor = '#F8D6D4';
      color = '#E53B34';
      border = withBorder ? '1px solid #E53B34' : '1px solid transparent';
      break;
    case CampaignStatus.INACTIVE:
    default:
      backgroundColor = '#ECECEC';
      color = '#BBC0CC';
      border = withBorder ? '1px solid #BBC0CC' : '1px solid transparent';
      break;
  }

  return {
    display: 'inline-flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(0, 'auto'),
    border,
    backgroundColor,
    color,
    cursor: withSelector ? 'pointer' : 'default',
    p: {
      fontSize: theme.typography.pxToRem(14),
      color: withSelector ? '#6E7191' : color,
    },
    ...(withSelector
      ? {
          transition: 'background-color 0.5s',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }
      : {}),
  };
};

export const StyledStatusBadge = styled(Box, {
  shouldForwardProp,
})<StyledStatusBadgeProps>(styleBadge);

export const StyledStatusBadgeButton = styled(ButtonBase, {
  shouldForwardProp,
})<StyledStatusBadgeProps>(styleBadge);
