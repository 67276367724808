'use client';

import { type ElementRef, forwardRef, useState } from 'react';
import {
  CircleStencil,
  Cropper,
  type CropperProps,
  type CropperRef,
  RectangleStencil,
} from 'react-mobile-cropper';
import { styled } from '@mui/material';

import 'react-mobile-cropper/dist/style.css';

type ImageCropperProps = CropperProps;

const StyledCropper = styled(Cropper)({
  color: 'white',
});

export const ImageCropper = forwardRef<
  ElementRef<typeof StyledCropper>,
  ImageCropperProps
>(
  (
    { src, stencilComponent, stencilProps, sizeRestrictions, ...rest },
    forwardedRef
  ) => {
    const [image] = useState(src);

    const defaultSizeRestriction = {
      minHeight: 180,
      minWidth: 180,
      maxHeight: 12000,
      maxWidth: 12000,
    };

    return (
      <StyledCropper
        stencilProps={{ grid: false, ...stencilProps }}
        sizeRestrictions={sizeRestrictions ?? defaultSizeRestriction}
        stencilComponent={stencilComponent ?? RectangleStencil}
        src={image}
        ref={forwardedRef}
        {...rest}
      />
    );
  }
);

ImageCropper.displayName = 'ImageCropper';

export type { CropperRef, ImageCropperProps };
export { CircleStencil, RectangleStencil };
