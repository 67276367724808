import { useQuery } from '@tanstack/react-query';

import { serviceApi } from '~/features';
import { clearParams } from '~/shared';
import { ReportsCampaignsDto, ReportsCampaignsModelView } from '~/types';

export const GET_REPORTS_CAMPAIGNS_QUERY_KEY = 'GET_REPORTS_CAMPAIGNS_QUERY';

function getReportsCampaigns(params: ReportsCampaignsDto) {
  const clearedParams = clearParams(params) as ReportsCampaignsDto;
  return serviceApi.get<ReportsCampaignsModelView>('/Report/Campaigns', {
    params: clearedParams,
  });
}

export function useGetReportsCampaigns(params: ReportsCampaignsDto) {
  return useQuery({
    queryKey: [GET_REPORTS_CAMPAIGNS_QUERY_KEY, params],
    queryFn: () => getReportsCampaigns(params),
    select: data => data.data,
  });
}
