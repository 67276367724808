import { type ReactNode } from 'react';
import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog as DSDialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
} from '@mui/material';

type CustomDialogProps = {
  title?: string;
  onClose?: () => void;
  onCloseTransitionEnded?: () => void;
  withIcon?: ReactNode;
  withCloseButton?: boolean;
  centerTitle?: boolean;
};

export function Dialog({
  title,
  onClose,
  onCloseTransitionEnded,
  children,
  fullWidth = true,
  maxWidth,
  withIcon,
  withCloseButton = true,
  centerTitle,
  ...props
}: Omit<DialogProps, 'onClose'> & CustomDialogProps) {
  function handleClose() {
    onClose?.();
  }

  function handleTransitionEnd() {
    if (!props.open) {
      onCloseTransitionEnded?.();
    }
  }
  return (
    <DSDialog
      fullWidth={fullWidth}
      maxWidth={maxWidth ?? 'xs'}
      onClose={handleClose}
      onTransitionEnd={handleTransitionEnd}
      keepMounted={false}
      {...props}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: centerTitle ? 'center' : 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            flexDirection: 'column',
            pt: 2,
          }}
        >
          <Box sx={{ ml: 3 }}>{!!withIcon && withIcon}</Box>
          {title && (
            <DialogTitle
              sx={{
                fontSize: theme => theme.typography.pxToRem(28),
              }}
            >
              {title}
            </DialogTitle>
          )}
        </Box>
        {withCloseButton && (
          <IconButton onClick={() => handleClose()}>
            <Close />
          </IconButton>
        )}
      </Box>
      <DialogContent>{children}</DialogContent>
    </DSDialog>
  );
}
