'use client';

import { type Dispatch, type SetStateAction } from 'react';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@mui/icons-material/';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  type SelectChangeEvent,
} from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const WEEKDAYS = [
  { id: '0', name: 'Domingo' },
  { id: '1', name: 'Segunda' },
  { id: '2', name: 'Terça' },
  { id: '3', name: 'Quarta' },
  { id: '4', name: 'Quinta' },
  { id: '5', name: 'Sexta' },
  { id: '6', name: 'Sábado' },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MENU_PROPS = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 9.8 + ITEM_PADDING_TOP,
    },
  },
};

export function WeekSelect({
  selectedWeekdays,
  setter,
  id,
  label,
  fullWidth = true,
}: {
  selectedWeekdays: string[];
  setter: Dispatch<SetStateAction<string[]>>;
  id?: string;
  label?: string;
  fullWidth?: boolean;
}) {
  const handleChange = ({ target }: SelectChangeEvent<string[]>) => {
    const { value } = target;
    setter(typeof value === 'string' ? value.split(',') : value);
  };

  const labelSelect = label ?? 'Dias da semana';

  return (
    <FormControl fullWidth={fullWidth} variant="filled">
      <InputLabel id="weekdays-select-label">{labelSelect}</InputLabel>
      <Select
        sx={{ borderRadius: 0 }}
        multiple
        label={labelSelect}
        placeholder={labelSelect}
        labelId="weekdays-select-label"
        id={id ?? 'weekdays-select'}
        onChange={handleChange}
        value={selectedWeekdays}
        renderValue={selected =>
          selected
            .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
            .map(sId => WEEKDAYS.find(weekday => weekday.id === sId)?.name)
            .join(', ')
        }
        MenuProps={MENU_PROPS}
      >
        {WEEKDAYS.map(weekday => (
          <MenuItem key={weekday.name} value={weekday.id}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selectedWeekdays.indexOf(weekday.id) > -1}
            />
            <ListItemText primary={weekday.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
