import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { serviceApi } from '~/features';
import { ReportsCampaignDto } from '~/types';

export const DOWNLOAD_REPORTS_CAMPAIGN_MUTATION_KEY =
  'DOWNLOAD_REPORTS_CAMPAIGN_MUTATION';

async function exportExcel(params: ReportsCampaignDto) {
  return serviceApi.get<Blob>('/Report/Banners-Export', {
    params,
    responseType: 'blob',
  });
}

export function useDownloadReportsCampaign(
  onSuccess?: (
    data: AxiosResponse<Blob, unknown>,
    variables: ReportsCampaignDto,
    context: ReportsCampaignDto
  ) => unknown,
  onError?: (
    error: Error,
    variables: ReportsCampaignDto,
    context: ReportsCampaignDto | undefined
  ) => unknown
) {
  return useMutation({
    mutationKey: [DOWNLOAD_REPORTS_CAMPAIGN_MUTATION_KEY],
    mutationFn: async (params: ReportsCampaignDto) => exportExcel(params),
    onSuccess,
    onError,
  });
}
