'use client';

import isPropValid from '@emotion/is-prop-valid';
import { Box, styled } from '@mui/material';

type StyledSearchBoxContainerProps = {
  isLoading?: boolean;
  fullWidth?: boolean;
};

export const StyledSearchBoxContainer = styled(Box, {
  shouldForwardProp: (prop: string) => isPropValid(prop),
})<StyledSearchBoxContainerProps>(({ theme, isLoading, fullWidth }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '55px',
  borderTop:
    theme.palette.mode === 'light' ? '1px solid rgba(245, 245, 245, 0.5)' : '0',
  opacity: isLoading ? 0.3 : 1,
  pointerEvents: isLoading ? 'none' : 'auto',
  transition: theme.transitions.create(['opacity'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.short,
  }),
  borderRadius: `0`,
  overflow: 'hidden',
  ...(fullWidth && { width: '100%' }),
}));

export const StyledSearchBoxIcon = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.42)' : 'rgba(255, 255, 255, 0.7)'}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.typography.body1.color,
  width: '35px',
  height: '100%',
  flexGrow: 1,
  padding: `0 ${theme.spacing(1)}`,
  backgroundColor:
    theme.palette.mode === 'light'
      ? 'rgba(245, 245, 245, 0.5)'
      : 'rgba(50, 50, 50, 0.5)',
  transition: theme.transitions.create(['color'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shortest,
  }),
  borderRadius: 0,
}));
