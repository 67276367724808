import { Typography } from '@mui/material';

export function PlatformBadge({
  isApp,
  isWeb,
}: {
  isApp?: boolean;
  isWeb?: boolean;
}) {
  return (
    <Typography
      sx={{ display: 'flex', gap: '1px', fontWeight: 400 }}
      variant="body2"
      component="p"
    >
      {isApp && 'App'}
      {isApp && isWeb && '/'}
      {isWeb && 'Web'}
      {!isApp && !isWeb && '-'}
    </Typography>
  );
}
