import { useQuery } from '@tanstack/react-query';

import { serviceApi } from '~/features';
import { clearParams } from '~/shared';
import { ReportsBannerDto, ReportsBannerModelView } from '~/types';

export const GET_REPORTS_BANNER_QUERY_KEY = 'GET_REPORTS_BANNER_QUERY';

function getReportsBanner(params: ReportsBannerDto) {
  const clearedParams = clearParams(params) as ReportsBannerDto;
  return serviceApi.get<ReportsBannerModelView>('/Report/DailyRegister', {
    params: clearedParams,
  });
}

export function useGetReportsBanner(params: ReportsBannerDto) {
  return useQuery({
    queryKey: [GET_REPORTS_BANNER_QUERY_KEY, params],
    queryFn: () => getReportsBanner(params),
    select: data => data.data,
  });
}
