'use client';

import { useEffect } from 'react';
import { signOut } from 'next-auth/react';

import {
  COOKIE_KEYS,
  nextTickScheduler,
  type SetCookieFunction,
} from '~/shared';

export function useUserNonce(setCookie: SetCookieFunction, userNonce?: string) {
  useEffect(() => {
    async function checkNonce() {
      const USER_NONCE = process.env.NEXT_PUBLIC_USER_NONCE;

      if (typeof USER_NONCE === 'string') {
        if (!userNonce) {
          await setCookie(COOKIE_KEYS.USER_NONCE, USER_NONCE);
          return;
        }

        if (userNonce !== USER_NONCE) {
          // eslint-disable-next-line no-alert
          alert('Sua sessão expirou, por favor, faça login novamente.');
          nextTickScheduler(async () => {
            await setCookie(COOKIE_KEYS.USER_NONCE, USER_NONCE);
            signOut();
          });
        }
      }
    }

    checkNonce();
  }, [setCookie, userNonce]);
}
