'use client';

import {
  CircularProgress,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { StyledHeaderTableCell, StyledTable } from '~/shared';
import type { Advertiser } from '~/types';

import { useGetAdvertisers } from '../../hooks/useGetAdvertisers';

import { AdvertiserRow } from './Row';

export type AdvertisersListProps = {
  useGetAdvertisersQuery: ReturnType<typeof useGetAdvertisers>;
  onDelete: (advertiser: Advertiser) => void;
  onEdit: (advertiser: Advertiser) => void;
};

export function AdvertisersList({
  useGetAdvertisersQuery,
  onDelete,
  onEdit,
}: AdvertisersListProps) {
  const { data, isLoading, isError, error } = useGetAdvertisersQuery;

  return (
    <StyledTable aria-label="Tabela de Anunciantes">
      <TableHead>
        <TableRow>
          <StyledHeaderTableCell align="center" sx={{ width: '60px' }}>
            ID
          </StyledHeaderTableCell>
          <StyledHeaderTableCell>Nome do Anunciante</StyledHeaderTableCell>
          <StyledHeaderTableCell align="center" sx={{ width: '200px' }}>
            Ações
          </StyledHeaderTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading && (
          <TableRow>
            <TableCell colSpan={3} align="center">
              <CircularProgress size={15} /> Carregando
            </TableCell>
          </TableRow>
        )}
        {!isLoading && !isError && data?.list?.length === 0 && (
          <TableRow>
            <TableCell colSpan={3} align="center">
              Sem resultados
            </TableCell>
          </TableRow>
        )}
        {isError && (
          <TableRow>
            <TableCell
              sx={{ color: theme => theme.palette.error.light }}
              colSpan={3}
              align="center"
            >
              <Typography>Ocorreu um erro: {error.name}</Typography>
              <Typography>{error.message}</Typography>
            </TableCell>
          </TableRow>
        )}
        {!isLoading && !isError
          ? data?.list?.map(advertiser => (
              <AdvertiserRow
                key={advertiser.id}
                advertiser={advertiser}
                onDelete={onDelete}
                onEdit={onEdit}
              />
            ))
          : null}
      </TableBody>
    </StyledTable>
  );
}
