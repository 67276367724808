import { useRouter } from 'next/navigation';
import { TableCell, TableRow } from '@mui/material';

import { ProcessedName, StyledIdTableCell } from '~/shared';
import { AdvertiserReport } from '~/types';

export type ReportAdvertisersRowProps = {
  resume: AdvertiserReport;
};

export function ReportAdvertisersRow({ resume }: ReportAdvertisersRowProps) {
  const router = useRouter();

  function handleClick() {
    router.push(`/relatorios/${resume.advertiser.id}`);
  }

  // const downloadReportsCampaignsMutation = useDownloadReportsCampaigns(res => {
  //   const nowFormatted = format(new Date(), 'dd-MM-yyyy HH-mm');
  //   const filename = `Relatório Anunciante - ${resume.advertiser?.name ?? 'Sem Nome'} - ${nowFormatted}.xlsx`;
  //   const blob = new Blob([res.data], { type: res.headers['content-type'] });
  //   const downloadUrl = window.URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.href = downloadUrl;
  //   a.download = filename;
  //   a.click();
  // });

  // function handleExportExcel() {
  //   downloadReportsCampaignsMutation.mutate({
  //     idAdvertiser: resume.advertiser.id,
  //     count: 9999,
  //     page: 1,
  //   });
  // }

  const getPlatformLabel = ({
    isApp,
    isWeb,
  }: Pick<AdvertiserReport, 'isApp' | 'isWeb'>) => {
    const appLabel = isApp ? 'App' : '';
    const webLabel = isWeb ? 'Web' : '';

    if (appLabel && webLabel) {
      return `${appLabel}/${webLabel}`;
    }
    if (appLabel || webLabel) {
      return `${appLabel}${webLabel}`;
    }
    return '-';
  };

  return (
    <TableRow
      key={resume.advertiser.id}
      sx={{
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        '&:hover': {
          '.MuiTableCell-body': {
            backgroundColor: theme => theme.palette.grey[100],
          },
        },
        '.MuiTableCell-body': {
          backgroundColor: 'background.paper',
        },
      }}
    >
      <StyledIdTableCell
        component="th"
        scope="row"
        align="center"
        onClick={handleClick}
      >
        {resume.advertiser.id}
      </StyledIdTableCell>
      <TableCell
        component="th"
        scope="row"
        sx={{ position: 'sticky', left: 0 }}
        onClick={handleClick}
      >
        <ProcessedName name={resume.advertiser.name} />
      </TableCell>
      <TableCell component="th" scope="row" onClick={handleClick}>
        {resume.impressions ?? '-'}
      </TableCell>
      <TableCell component="th" scope="row" onClick={handleClick}>
        {resume.clicks}
      </TableCell>
      <TableCell component="th" scope="row" onClick={handleClick}>
        {resume.ctr ?? '-'}
      </TableCell>
      <TableCell component="th" scope="row" onClick={handleClick}>
        {resume.cpm ?? '-'}
      </TableCell>
      <TableCell component="th" scope="row" onClick={handleClick}>
        {resume.cpc ?? '-'}
      </TableCell>
      <TableCell component="th" scope="row" onClick={handleClick}>
        {getPlatformLabel(resume)}
      </TableCell>
      <TableCell component="th" scope="row" onClick={handleClick}>
        {resume.totalInvestment ?? '-'}
      </TableCell>
      {/* <TableCell component="th" scope="row" align="center">
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={
            downloadReportsCampaignsMutation.isPending ? (
              <CircularProgress size={15} />
            ) : (
              <SaveAltSharpIcon />
            )
          }
          sx={{ textTransform: 'none' }}
          onClick={handleExportExcel}
          disabled={downloadReportsCampaignsMutation.isPending}
        >
          Baixar
        </Button>
      </TableCell> */}
    </TableRow>
  );
}
