'use client';

import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useState,
} from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { signIn } from 'next-auth/react';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

import { addToQueryString } from '~/shared';

import { StyledLoginForm } from './elements';

// TODO: find a way to remove dependency cycle on MartinsTokenRefreshSession because of AuthProviders export from campaign entity
enum AuthProviders {
  // AZURE_AD = 'azure-ad',
  MARTINS_CRED = 'martins-credentials',
  REFRESH_TOKEN = 'refresh-token',
  EXPIRED_MARTINS_CRED = 'expired-martins-credentials',
}

export function LoginForm({
  callbackUrl,
  errorParam,
  isSubmitting,
  setIsSubmitting,
  onLoginSuccess,
}: {
  callbackUrl?: string;
  errorParam?: string;
  isSubmitting: `${AuthProviders}` | null;
  setIsSubmitting: Dispatch<SetStateAction<`${AuthProviders}` | null>>;
  onLoginSuccess?: () => void;
}) {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const addSearchParam = useCallback(addToQueryString, [searchParams]);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  function handleValidation() {
    setUsernameError(username === '' ? 'Digite um usuário' : '');
    setPasswordError(password === '' ? 'Digite uma senha' : '');
    return username !== '' && password !== '';
  }

  async function handleSignIn() {
    setIsSubmitting(AuthProviders.MARTINS_CRED);
    if (!handleValidation()) {
      setIsSubmitting(null);
      return;
    }
    try {
      await signIn(AuthProviders.MARTINS_CRED, {
        username,
        password,
        callbackUrl,
        redirect: !!callbackUrl,
      });

      onLoginSuccess?.();
    } catch (error) {
      const urlParams = addSearchParam(
        'error',
        'SERVICE_UNAVAILABLE',
        searchParams.toString()
      );
      router.push(`${pathname}?${urlParams}`);
      setIsSubmitting(null);
    }
  }
  return (
    <StyledLoginForm>
      {errorParam && !isSubmitting && (
        <Typography sx={{ color: theme => theme.palette.error.light }}>
          {errorParam}
        </Typography>
      )}

      <TextField
        disabled={!!isSubmitting}
        value={username}
        onChange={e => {
          const value = e.target.value.trim();
          setUsername(value);
          setUsernameError(value === '' ? 'Digite um usuário' : '');
        }}
        error={usernameError !== ''}
        helperText={usernameError}
        id="username"
        type="text"
        label="Usuário"
        variant="filled"
      />
      <TextField
        disabled={!!isSubmitting}
        value={password}
        onChange={e => {
          const value = e.target.value.trim();
          setPassword(value);
          setPasswordError(value === '' ? 'Digite uma senha' : '');
        }}
        error={passwordError !== ''}
        helperText={passwordError}
        id="password"
        type={showPassword ? 'text' : 'password'}
        label="Senha"
        variant="filled"
        sx={{ marginBottom: theme => theme.spacing(4) }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {showPassword ? (
                  <VisibilityOutlined />
                ) : (
                  <VisibilityOffOutlined />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Button
        disabled={!!isSubmitting || username === '' || password === ''}
        type="submit"
        color="primary"
        variant="contained"
        sx={{ textTransform: 'none' }}
        onClick={e => {
          e.preventDefault();
          handleSignIn();
        }}
        size="large"
      >
        Acessar Conta
      </Button>
    </StyledLoginForm>
  );
}
