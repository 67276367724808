export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DRAFT = 'DRAFT',
  REVIEW = 'REVIEW',
  ATTENTION = 'ATTENTION', // temporarily disabled
  PAUSED = 'PAUSED',
  DONE = 'DONE',
}

interface Role {
  id: number;
  name: string;
}

export type User = {
  id?: string;
  name: string;
  role: Role;
  email: string;
  deleted?: boolean;
  createAt?: string;
  userMartins: string;
};

export type UserDetail = {
  id: string;
};
