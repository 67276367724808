import type { ThemeOptions } from '@mui/material';

export const darkTheme: Partial<ThemeOptions> = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#466aeb',
      // main: '#2B52DD',
      light: '#7c99ff',
      dark: '#072ad8',
    },
    text: {
      primary: '#eff0f6',
      secondary: '#6e7191',
    },
    background: {
      paper: '#151515',
    },
  },
  components: {
    MuiTextField: {
      variants: [
        {
          props: { variant: 'filled' },
          style: {
            '.MuiFilledInput-root': {
              backgroundColor: 'rgba(50, 50, 50, 0.5)',
              borderRadius: 0,
              '&:hover': {
                backgroundColor: 'rgba(50, 50, 50, 0.3)',
              },
            },
          },
        },
      ],
    },
  },
};
