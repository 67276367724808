import { BorderColorOutlined } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

export function BannerController({
  editImageAction,
  imageUrl,
}: {
  editImageAction: () => void;
  imageUrl?: string;
}) {
  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Button
        size="small"
        startIcon={<BorderColorOutlined />}
        variant="contained"
        color="primary"
        sx={{ px: 2, py: 0.8 }}
        onClick={editImageAction}
      >
        {imageUrl ? 'Editar' : 'Adicionar'}
      </Button>

      {/* <Button
        size="small"
        startIcon={<RemoveRedEyeOutlined />}
        variant="contained"
        color="primary"
        sx={{ px: 2, py: 0.8 }}
      >
        Visualizar
      </Button> */}
    </Box>
  );
}
