import Image from 'next/image';

import { SplashScreenBox, StyledImageBlock, StyledLogoBlock } from './elements';

export function SplashScreen() {
  return (
    <SplashScreenBox>
      <StyledLogoBlock>
        <Image
          src="/static/logo_martins_ads.svg"
          alt="Logo da Martins Ads"
          fill
          sizes="100% 100%"
          style={{
            objectFit: 'contain',
            objectPosition: 'center center',
          }}
        />
      </StyledLogoBlock>
      <StyledImageBlock>
        <Image
          className="splash"
          src="/static/login_splash.png"
          alt="Ilustração de vetor de uma mão tocando a tela do notebook"
          fill
          sizes="100% 100%"
          style={{
            objectFit: 'contain',
            objectPosition: 'bottom right',
          }}
        />
        <Image
          className="mobile-splash"
          src="/static/login_splash_mobile.png"
          alt="Ilustração de vetor de uma mão tocando a tela do notebook"
          fill
          sizes="100% 100%"
          style={{
            objectFit: 'contain',
            objectPosition: 'top left',
          }}
        />
      </StyledImageBlock>
    </SplashScreenBox>
  );
}
