import { useQuery } from '@tanstack/react-query';

import { serviceApi } from '~/features';
import { type Advertiser } from '~/types';

export const GET_ADVERTISER_QUERY_KEY = 'GET_ADVERTISER_QUERY';

async function getAdvertiser(id: string) {
  return serviceApi.get<Advertiser>(`/Anunciante?id=${id}`);
}

export function useGetAdvertiser(id: string) {
  return useQuery({
    queryKey: [GET_ADVERTISER_QUERY_KEY, id],
    select: data => data?.data,
    queryFn: () => getAdvertiser(id),
  });
}
