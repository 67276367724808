import { useQuery } from '@tanstack/react-query';

import { serviceApi } from '~/features';
import { clearParams } from '~/shared';
import { DashCampaignsDto, DashCampaignsModelView } from '~/types';

export const GET_DASHBOARD_QUERY_KEY = 'GET_DASHBOARD_QUERY';

function getDashboard(params: DashCampaignsDto) {
  const clearedParams = clearParams(params) as DashCampaignsDto;
  return serviceApi.get<DashCampaignsModelView>(
    '/CalculoCampanha/dashboard-campanhas',
    { params: clearedParams }
  );
}

export function useGetDashboard(params: DashCampaignsDto) {
  return useQuery({
    queryKey: [GET_DASHBOARD_QUERY_KEY, params],
    queryFn: () => getDashboard(params),
    select: data => data.data,
  });
}
