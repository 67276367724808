import { CustomIconsProps } from './types';

export function CampanhasIcon({
  size = 24,
  color = 'currentColor',
}: CustomIconsProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00002 8.00002H21C21.6 8.00002 22 7.60002 22 7.00002C22 6.40002 21.6 6.00002 21 6.00002H7.00002C6.40002 6.00002 6.00002 6.40002 6.00002 7.00002C6.00002 7.60002 6.40002 8.00002 7.00002 8.00002ZM21 11H7.00002C6.40002 11 6.00002 11.4 6.00002 12C6.00002 12.6 6.40002 13 7.00002 13H21C21.6 13 22 12.6 22 12C22 11.4 21.6 11 21 11ZM21 16H7.00002C6.40002 16 6.00002 16.4 6.00002 17C6.00002 17.6 6.40002 18 7.00002 18H21C21.6 18 22 17.6 22 17C22 16.4 21.6 16 21 16ZM3.70002 6.30002C3.60002 6.20002 3.50002 6.10002 3.40002 6.10002C3.00002 5.90002 2.60002 6.00002 2.30002 6.30002C2.20002 6.40002 2.10002 6.50002 2.10002 6.60002C2.00002 6.90002 2.00002 7.10002 2.10002 7.40002C2.20002 7.50002 2.20002 7.60002 2.30002 7.70002C2.40002 7.80002 2.50002 7.90002 2.60002 7.90002C2.70002 8.00002 2.90002 8.00002 3.00002 8.00002C3.30002 8.00002 3.50002 7.90002 3.70002 7.70002C3.80002 7.60002 3.90002 7.50002 3.90002 7.40002C4.00002 7.10002 4.00002 6.90002 3.90002 6.60002C3.90002 6.50002 3.80002 6.40002 3.70002 6.30002ZM3.70002 11.3C3.40002 11 3.00002 10.9 2.60002 11.1C2.50002 11.2 2.40002 11.2 2.30002 11.3C2.20002 11.4 2.10002 11.5 2.10002 11.6C2.00002 11.8 2.00002 12.1 2.10002 12.4C2.20002 12.5 2.20002 12.6 2.30002 12.7C2.40002 12.8 2.50002 12.9 2.60002 12.9C2.70002 13 2.90002 13 3.00002 13C3.10002 13 3.30002 13 3.40002 12.9C3.50002 12.8 3.60002 12.8 3.70002 12.7C3.80002 12.6 3.90002 12.5 3.90002 12.4C4.00002 12.2 4.00002 11.9 3.90002 11.6C3.90002 11.5 3.80002 11.4 3.70002 11.3ZM3.70002 16.3C3.60002 16.2 3.50002 16.1 3.40002 16.1C3.20002 16 2.90002 16 2.60002 16.1C2.50002 16.1 2.40002 16.2 2.30002 16.3C2.20002 16.4 2.10002 16.5 2.10002 16.6C1.90002 17 2.00002 17.4 2.30002 17.7C2.40002 17.8 2.50002 17.9 2.60002 17.9C2.70002 18 2.90002 18 3.00002 18C3.10002 18 3.30002 18 3.40002 17.9C3.50002 17.8 3.60002 17.8 3.70002 17.7C4.00002 17.4 4.10002 17 3.90002 16.6C3.90002 16.5 3.80002 16.4 3.70002 16.3Z"
        fill={color}
      />
    </svg>
  );
}
