'use client';

import { type ComponentProps, useEffect, useMemo, useState } from 'react';
import { type PaletteMode, useMediaQuery } from '@mui/material';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v13-appRouter';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ptBR } from 'date-fns/locale/pt-BR';

import {
  COOKIE_KEYS,
  type GetCookieFunction,
  type SetCookieFunction,
} from '~/shared';

import { DarkTheme, LightTheme } from '../variations';

import { ColorModeContext } from './context';

export type ThemeProviderProps = Omit<
  ComponentProps<typeof MuiThemeProvider>,
  'theme'
> & {
  jest?: boolean;
  colorMode?: PaletteMode;
  getCookie: GetCookieFunction;
  setCookie: SetCookieFunction;
};

export function ThemeProvider({
  children,
  jest = false,
  colorMode,
  getCookie,
  setCookie,
  ...props
}: ThemeProviderProps) {
  // TODO: for now, we are forcing light mode
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const userPreference = 'light'; // prefersDarkMode ? 'dark' : 'light';
  const [mode, setMode] = useState<PaletteMode>(colorMode ?? userPreference);

  useEffect(() => {
    if (!colorMode) {
      setMode(userPreference);
      setCookie(COOKIE_KEYS.USER_COLOR_MODE, userPreference);
    }
  }, [mode, colorMode, userPreference, setCookie]);

  const providerValue = useMemo(
    () => ({
      toggleColorMode: async () => {
        const nextColorMode = mode === 'light' ? 'dark' : 'light';
        setCookie(COOKIE_KEYS.USER_COLOR_MODE, nextColorMode);
        setMode(nextColorMode);
      },
      palletMode: mode,
    }),
    [mode, setCookie]
  );

  const theme = useMemo(
    () => createTheme(mode === 'light' ? LightTheme : DarkTheme),
    [mode]
  );

  return (
    <AppRouterCacheProvider options={{ enableCssLayer: !jest }}>
      <ColorModeContext.Provider value={providerValue}>
        <MuiThemeProvider {...props} theme={theme}>
          <LocalizationProvider
            adapterLocale={ptBR}
            dateAdapter={AdapterDateFns}
          >
            {children}
          </LocalizationProvider>
        </MuiThemeProvider>
      </ColorModeContext.Provider>
    </AppRouterCacheProvider>
  );
}
