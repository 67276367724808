'use client';

import { Box, Card, styled } from '@mui/material';

export const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  boxSizing: 'border-box',
  width: '100%',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.mode === 'light' ? 'white' : '#2e2e2e',
}));

export const StyledIconCard = styled(Box)(({ theme }) => ({
  position: 'absolute',
  boxSizing: 'border-box',
  top: theme.spacing(2),
  right: theme.spacing(2),
  padding: theme.spacing(1, 1.5),
  borderRadius: theme.shape.borderRadius - 2,
  '& svg': { width: '20px' },
}));
