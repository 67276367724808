import { styled, Typography } from '@mui/material';

export const StyledList = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
});

export const StyledListItem = styled('li')({
  display: 'flex',
  alignItems: 'center',
  a: {
    textDecoration: 'none',
  },
});

export const StyledSeparator = styled('span')({
  margin: '0 8px',
  marginTop: '4px',
  color: '#a0a3bd',
});

export const StyledContextText = styled('span')({
  color: '#6e7191',
  whiteSpace: 'nowrap',
});

export const StyledItemText = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.text.primary,
    fontWeight: 500,
    whiteSpace: 'nowrap',
  };
});
