'use client';

import {
  CircularProgress,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { stickNameCell, StyledHeaderTableCell, StyledTable } from '~/shared';
import { BannersKey } from '~/types';

import { useGetReportsBanner } from '../../hooks';

import { ReportsBannerRow } from './Row';

export type ReportsBannerListProps = {
  getReportsBannerQuery: ReturnType<typeof useGetReportsBanner>;
  advertiserId: string;
  campaignId: string;
  bannerKey?: BannersKey;
};

export function ReportsBannerList({
  getReportsBannerQuery,
  advertiserId,
  campaignId,
  bannerKey,
}: ReportsBannerListProps) {
  const { data, isLoading, isError, error } = getReportsBannerQuery;

  return (
    <StyledTable aria-label="Tabela de Relatórios">
      <TableHead>
        <TableRow>
          <StyledHeaderTableCell sx={stickNameCell('auto')}>
            Data
          </StyledHeaderTableCell>
          <StyledHeaderTableCell>Plataforma</StyledHeaderTableCell>
          <StyledHeaderTableCell>Impressões</StyledHeaderTableCell>
          <StyledHeaderTableCell>Cliques</StyledHeaderTableCell>
          <StyledHeaderTableCell>CTR</StyledHeaderTableCell>
          <StyledHeaderTableCell>CPM</StyledHeaderTableCell>
          <StyledHeaderTableCell>CPC</StyledHeaderTableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {isLoading && (
          <TableRow>
            <TableCell component="th" scope="row" colSpan={8} align="center">
              <CircularProgress size={15} /> Carregando
            </TableCell>
          </TableRow>
        )}

        {!isLoading && !isError && data?.list?.length === 0 && (
          <TableRow>
            <TableCell colSpan={8} align="center">
              Sem resultados
            </TableCell>
          </TableRow>
        )}

        {isError && (
          <TableRow>
            <TableCell
              sx={{ color: theme => theme.palette.error.light }}
              colSpan={8}
              align="center"
            >
              <Typography>Ocorreu um erro: {error.name}</Typography>
              <Typography>{error.message}</Typography>
            </TableCell>
          </TableRow>
        )}

        {!isLoading &&
          bannerKey &&
          !isError &&
          data?.list?.map(resume => {
            return (
              <ReportsBannerRow
                key={bannerKey.key}
                resume={resume}
                advertiserId={advertiserId}
                campaignId={campaignId}
              />
            );
          })}
      </TableBody>
    </StyledTable>
  );
}
