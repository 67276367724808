import { useQuery } from '@tanstack/react-query';

import { serviceApi } from '~/features';
import { BannersKeysModelView } from '~/types';

export const GET_BANNERS_KEYS_QUERY_KEY = 'GET_BANNERS_KEYS_QUERY';

function getBannersKeys() {
  return serviceApi.get<BannersKeysModelView>('/banner/keys');
}

export function useGetBannersKeys() {
  return useQuery({
    queryKey: [GET_BANNERS_KEYS_QUERY_KEY],
    queryFn: getBannersKeys,
    select: data => {
      return {
        app: data.data.filter(key => key.device === 'APP'),
        web: data.data.filter(key => key.device === 'DESKTOP'),
        options: data.data,
      };
    },
  });
}

export type UseGetBannersKeysReturn = ReturnType<
  typeof useGetBannersKeys
>['data'];
