import { DatePickerProps } from '@mui/x-date-pickers';

export function datePickerSlotProps(
  errorMessage?: string
): DatePickerProps<Date>['slotProps'] {
  return {
    textField: {
      error: !!errorMessage,
      helperText: errorMessage,
      variant: 'filled',
    },
    actionBar: {
      actions: ['clear'],
    },
  };
}
