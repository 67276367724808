import { useQuery } from '@tanstack/react-query';

import { serviceApi } from '~/features';
import { clearParams } from '~/shared';
import { ReportsCampaignDto, ReportsCampaignModelView } from '~/types';

export const GET_REPORTS_CAMPAIGN_QUERY_KEY = 'GET_REPORTS_CAMPAIGN_QUERY';

function getReportsCampaign(params: ReportsCampaignDto) {
  const clearedParams = clearParams(params) as ReportsCampaignDto;
  return serviceApi.get<ReportsCampaignModelView>('/Report/Banners', {
    params: clearedParams,
  });
}

export function useGetReportsCampaign(params: ReportsCampaignDto) {
  return useQuery({
    queryKey: [GET_REPORTS_CAMPAIGN_QUERY_KEY, params],
    queryFn: () => getReportsCampaign(params),
    select: data => data.data,
  });
}
