import { TableCell, TableRow } from '@mui/material';

import { CampaignBannerDailyReport } from '~/types';

export type ReportsBannerRowProps = {
  resume: CampaignBannerDailyReport;
  advertiserId: string;
  campaignId: string;
};

export function ReportsBannerRow({
  resume,
  advertiserId,
  campaignId,
}: ReportsBannerRowProps) {
  return (
    <TableRow
      key={`${advertiserId}_${campaignId}`}
      sx={{
        whiteSpace: 'nowrap',
        '&:hover': {
          '.MuiTableCell-body': {
            backgroundColor: theme => theme.palette.grey[100],
          },
        },
        '.MuiTableCell-body': {
          backgroundColor: 'background.paper',
        },
      }}
    >
      <TableCell
        component="th"
        scope="row"
        sx={{ position: 'sticky', left: 0 }}
      >
        {resume.date.replaceAll('-', '/')}
      </TableCell>
      <TableCell component="th" scope="row">
        {resume.device ?? '-'}
      </TableCell>
      <TableCell component="th" scope="row">
        {resume.impressions ?? '-'}
      </TableCell>
      <TableCell component="th" scope="row">
        {resume.clicks ?? '-'}
      </TableCell>
      <TableCell component="th" scope="row">
        {resume.ctr ?? '-'}
      </TableCell>
      <TableCell component="th" scope="row">
        {resume.cpm ?? '-'}
      </TableCell>
      <TableCell component="th" scope="row">
        {resume.cpc ?? '-'}
      </TableCell>
    </TableRow>
  );
}
