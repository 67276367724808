'use client';

import { type MouseEvent, useState } from 'react';
import { Session } from 'next-auth';
import { signOut } from 'next-auth/react';
import {
  ArrowDropDown,
  DarkMode,
  LightMode,
  Logout,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@mui/material';

import { useColorMode } from '~/features';
import { stringAvatar } from '~/shared';

import { StyledUserMenuButton } from './elements';

export function UserContextMenu({ session }: { session: Session }) {
  const colorMode = useColorMode();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const userImage =
    session.user?.image !== null ? session.user?.image : undefined;
  const userName = session.user?.name !== null ? session.user?.name : undefined;

  return (
    <Box data-testid="user-context-menu">
      <StyledUserMenuButton onClick={handleClick}>
        <Avatar src={userImage} alt={userName} {...stringAvatar(userName)} />
        <span>{userName}</span>
        <ArrowDropDown />
      </StyledUserMenuButton>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 5px rgba(0,0,0,0.2))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          sx={{ '&:hover': { color: theme => theme.palette.error.main } }}
          onClick={() => {
            signOut();
            handleClose();
          }}
        >
          <ListItemIcon sx={{ color: 'currentColor' }}>
            <Logout fontSize="small" />
          </ListItemIcon>
          Sair
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => colorMode.toggleColorMode()}>
          <ListItemIcon>
            {colorMode.palletMode === 'dark' ? <LightMode /> : <DarkMode />}
          </ListItemIcon>
          Mudar Tema
        </MenuItem>
      </Menu>
    </Box>
  );
}
