'use client';

import { useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';
import { Box } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { jwtDecode } from 'jwt-decode';

import {
  LoadingSplashScreen,
  LoginForm,
  StyledHeader,
  StyledTextCall,
} from '~/entities/Login';
import { Dialog } from '~/shared';
import { DecodedMartinsToken } from '~/types';

import { AuthProviders } from '../types/providers';

function getExpiresInMs(t?: string) {
  if (!t) return null;
  const martinsToken = jwtDecode<DecodedMartinsToken>(t);
  return martinsToken.exp * 1000 - Date.now();
}

type MartinsTokenRefreshSessionProps = {
  st?: string;
};

export function MartinsTokenRefreshSession({
  st,
}: MartinsTokenRefreshSessionProps) {
  const session = useSession();
  const queryClient = useQueryClient();

  const exp = getExpiresInMs(session.data?.t.st ?? st);

  const [isSubmitting, setIsSubmitting] = useState<`${AuthProviders}` | null>(
    null
  );

  const [isMartinsTokenRefreshing, setIsMartinsTokenRefreshing] = useState(
    exp ? exp < 0 : false
  );

  function onLoginSuccess() {
    queryClient.refetchQueries();
    setIsMartinsTokenRefreshing(false);
    setIsSubmitting(null);
  }

  useEffect(() => {
    if (!exp) return () => {};

    if (exp < 0) {
      setIsMartinsTokenRefreshing(true);
      return () => {};
    }

    const expireTimeout = setTimeout(() => {
      setIsMartinsTokenRefreshing(true);
    }, exp);

    return () => clearTimeout(expireTimeout);
  }, [exp]);

  if (!st) return null;

  return !isMartinsTokenRefreshing ? null : (
    <>
      {!!isSubmitting && <LoadingSplashScreen isSubmitting={isSubmitting} />}
      <Dialog open maxWidth="sm" withCloseButton={false}>
        <StyledHeader sx={{ textAlign: 'center' }} variant="h1">
          Sua sessão Martins expirou
        </StyledHeader>
        <StyledTextCall sx={{ textAlign: 'center' }}>
          Faça login novamente
        </StyledTextCall>
        <Box sx={{ maxWidth: '420px', margin: '0 auto', marginTop: 2, p: 2 }}>
          <LoginForm
            onLoginSuccess={onLoginSuccess}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
          />
        </Box>
      </Dialog>
    </>
  );
}
