import { useQuery } from '@tanstack/react-query';

import { serviceApi } from '~/features';
import { type CampaignResultsDetailedModelView } from '~/types';

export const GET_CAMPAIGN_RESULTS_DETAILED_QUERY_KEY =
  'GET_CAMPAIGN_RESULTS_DETAILED_QUERY';

export async function getCampaignResultsDetailed(id: string) {
  return serviceApi.get<CampaignResultsDetailedModelView>(
    `/CalculoCampanha/calculo-detalhado?CampaignId=${id}`
  );
}

export function useGetCampaignResultsDetailed(id: string) {
  return useQuery({
    queryKey: [GET_CAMPAIGN_RESULTS_DETAILED_QUERY_KEY, id],
    queryFn: () => getCampaignResultsDetailed(id),
    select: data => data.data,
  });
}
