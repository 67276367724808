'use client';

import { type ReactNode, useState } from 'react';
import { useDebounce } from 'react-use';
import { useRouter } from 'next/navigation';
import { Box, Button, FormControlLabel, TextField } from '@mui/material';
import { isURL } from 'validator';

import { DefaultSwitch } from '~/shared';
import type { BannersKey, CampaignBanner, CampaignStatus } from '~/types';

import {
  StyledCampaignForm,
  StyledCampaignFormLabelTitle,
  StyledCampaignFormRow,
  StyledCampaignFormSubtitle,
} from '../../components/elements';
import { UseUpdateCampaignReturn } from '../../hooks/useUpdateCampaign';

import { BannerRow } from './BannerRow';

export function CampaignFormStepBanners({
  campaignId,
  campaignStatus,
  continueAction,
  cancelActionNode,
  campaignBanners,
  bannerType,
  bannerKeys,
  continueLabel,
  updateCampaignQuery,
  linkUrl,
  activeLink,
}: {
  campaignId: string;
  campaignStatus?: `${CampaignStatus}`;
  continueAction: () => void;
  cancelActionNode: ReactNode;
  campaignBanners?: CampaignBanner[];
  bannerType: 'web' | 'app';
  bannerKeys?: BannersKey[];
  continueLabel: string;
  updateCampaignQuery: UseUpdateCampaignReturn;
  linkUrl?: string | undefined | null;
  activeLink?: boolean;
}) {
  const router = useRouter();

  const [uniqueLink, setUniqueLink] = useState(activeLink);
  const [uniqueLinkUrl, setUniqueLinkUrl] = useState(linkUrl || '');
  const [uniqueLinkUrlError, setUniqueLinkUrlError] = useState('');

  useDebounce(
    () => {
      if (uniqueLink !== activeLink) {
        updateCampaignQuery.mutate({
          id: campaignId,
          ...(bannerType === 'web'
            ? { webLinkActive: uniqueLink }
            : { appLinkActive: uniqueLink }),
        });
      }
    },
    500,
    [uniqueLink]
  );

  useDebounce(
    () => {
      if (uniqueLink && uniqueLinkUrl !== linkUrl) {
        if (uniqueLinkUrl !== '' && !isURL(uniqueLinkUrl)) {
          setUniqueLinkUrlError('URL inválida');
          return;
        }
        setUniqueLinkUrlError('');
        updateCampaignQuery.mutate({
          id: campaignId,
          ...(bannerType === 'web'
            ? { webLink: uniqueLinkUrl }
            : { appLink: uniqueLinkUrl }),
        });
      }
    },
    500,
    [uniqueLinkUrl]
  );

  return (
    <StyledCampaignForm>
      <StyledCampaignFormSubtitle>
        Configurações dos banners
      </StyledCampaignFormSubtitle>

      <StyledCampaignFormRow sx={{ flexDirection: 'column' }}>
        <FormControlLabel
          id={`unique-link-${bannerType}`}
          control={<DefaultSwitch />}
          checked={uniqueLink}
          onChange={(_, checked) => setUniqueLink(checked)}
          label={
            <StyledCampaignFormLabelTitle>
              Link único para todos os banners
            </StyledCampaignFormLabelTitle>
          }
        />
        <TextField
          disabled={!uniqueLink}
          value={uniqueLinkUrl}
          error={!!uniqueLinkUrlError}
          helperText={uniqueLinkUrlError}
          onChange={event => setUniqueLinkUrl(event.target.value)}
          id={`campaign-${bannerType}-unique-link`}
          label="Endereço do link para onde o banner irá direcionar"
          placeholder="ex: https://www.google.com"
          variant="filled"
          sx={{ mt: -1 }}
        />
      </StyledCampaignFormRow>

      <StyledCampaignFormSubtitle sx={{ mt: 2 }}>
        Configurações individuais por anúncio
      </StyledCampaignFormSubtitle>

      <StyledCampaignFormRow sx={{ flexDirection: 'column', gap: 5 }}>
        {bannerKeys?.map(bannerPos => {
          const campaignBanner = campaignBanners?.find(
            banner => banner.key === bannerPos.key
          );
          return (
            <BannerRow
              key={bannerPos.key}
              campaignId={campaignId}
              bannerType={bannerType}
              updateCampaignQuery={updateCampaignQuery}
              bannerPosition={bannerPos}
              campaignBanner={campaignBanner}
            />
          );
        })}
      </StyledCampaignFormRow>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        {cancelActionNode}
        <Box sx={{ display: 'flex', gap: 2 }}>
          {campaignStatus === 'DRAFT' && (
            <Button
              variant="outlined"
              color="primary"
              size="large"
              sx={{ px: 3, py: 1.5 }}
              onClick={() => {
                updateCampaignQuery.mutate({
                  id: campaignId,
                  status: 'DRAFT',
                });
                router.push(`/campanhas/${campaignId}`);
              }}
            >
              Salvar Rascunho
            </Button>
          )}
          {campaignStatus === 'ACTIVE' && bannerType !== 'app' && (
            <Button
              variant="outlined"
              color="primary"
              size="large"
              sx={{ px: 3, py: 1.5 }}
              onClick={() => {
                router.push(`/campanhas/${campaignId}`);
              }}
            >
              Sair
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ px: 3, py: 1.5 }}
            onClick={() => continueAction()}
          >
            {continueLabel}
          </Button>
        </Box>
      </Box>
    </StyledCampaignForm>
  );
}
