'use client';

import { useEffect, useState } from 'react';
import { CheckCircleOutline } from '@mui/icons-material';
import { Box, CircularProgress, Typography } from '@mui/material';
import { formatDistance, type FormatDistanceOptions } from 'date-fns';
import { ptBR } from 'date-fns/locale/pt-BR';

const formatOptions: FormatDistanceOptions = {
  addSuffix: true,
  includeSeconds: true,
  locale: ptBR,
};

export function SavingStatusBadge({
  isLoading,
  isSaving,
  lastSaveDate,
  createdAt,
  saveMsg,
  createdMsg,
}: {
  isLoading: boolean;
  isSaving: boolean;
  lastSaveDate: Date | null;
  createdAt: Date | null;
  saveMsg?: string;
  createdMsg?: string;
}) {
  const [relativeTime, setRelativeTime] = useState('');

  useEffect(() => {
    if (!createdAt || (!lastSaveDate && !createdAt)) {
      setRelativeTime('');
      return;
    }
    const usedDate = lastSaveDate ?? createdAt;
    const formattedUsedDate = formatDistance(
      usedDate,
      new Date(new Date().getTime() + 4000),
      formatOptions
    );
    setRelativeTime(formattedUsedDate);
  }, [lastSaveDate, createdAt]);

  const neverSaved = !lastSaveDate;
  const haveSaved = lastSaveDate;
  const haveCreated = !lastSaveDate && createdAt;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {(isSaving || isLoading) && (
        <CircularProgress size="20px" sx={{ mr: 1 }} />
      )}
      {(haveSaved || neverSaved) && (
        <CheckCircleOutline fontSize="small" sx={{ color: '#2C51DA' }} />
      )}
      <Typography>
        {haveSaved && (
          <>
            {saveMsg ?? 'Alterações salvas:'} <strong>{relativeTime}</strong>
          </>
        )}
        {haveCreated && (
          <>
            {createdMsg ?? 'Criado:'} <strong>{relativeTime}</strong>
          </>
        )}
      </Typography>
    </Box>
  );
}
