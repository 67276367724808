import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  TextField,
} from '@mui/material';

import { CheckboxCheckedIcon, CheckboxIcon } from '~/shared';
import type { BannersKey } from '~/types';

import { useGetBannersKeys } from '../hooks';

export type BannersKeysSelectProps = {
  id: string;
  label: string;
  value?: Pick<BannersKey, 'key' | 'name'>[];
  onChange: (value: Pick<BannersKey, 'key' | 'name'>[]) => void;
  disabled?: boolean;
};

export function BannersKeysSelect({
  id,
  value,
  onChange,
  label,
  disabled,
}: BannersKeysSelectProps) {
  const { isLoading, isError, data } = useGetBannersKeys();

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      disabled={disabled || isLoading || isError}
      sx={{ flexGrow: 1, flexBasis: '100%' }}
      id={`segment-selections${id ? `_${id}` : ''}`}
      options={data?.options ?? [{ key: '', name: '' }]}
      getOptionLabel={option => option.name}
      getOptionKey={option => option.key}
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={CheckboxIcon}
            checkedIcon={CheckboxCheckedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          variant="filled"
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress
                    color="inherit"
                    size={25}
                    sx={{ mt: '-18px' }}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
