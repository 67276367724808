import { Button, styled } from '@mui/material';

export const StyledUserMenuButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(1),
  color: theme.palette.text.primary,
  textTransform: 'none',
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  span: {
    whiteSpace: 'nowrap',
  },
  [theme.breakpoints.down('md')]: {
    span: {
      display: 'none',
    },
  },
}));
