'use client';

import { createContext } from 'react';
import type { PaletteMode } from '@mui/material';

type ColorModeContextType = {
  toggleColorMode: () => void;
  palletMode: PaletteMode;
};

export const ColorModeContext = createContext<ColorModeContextType>(
  {} as ColorModeContextType
);
