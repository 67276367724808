import { CustomIconsProps } from './types';

export function GreenCheck({ size = 24 }: Omit<CustomIconsProps, 'color'>) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="40" cy="40" r="40" fill="#E4F5EA" />
      <path
        d="M52.0623 31.9999C51.3645 31.3021 50.3177 31.3021 49.6199 31.9999L36.5358 45.084L31.1277 39.6759C30.4299 38.9781 29.3832 38.9781 28.6854 39.6759C27.9875 40.3738 27.9875 41.4205 28.6854 42.1183L35.3146 48.7476C35.6635 49.0965 36.0125 49.271 36.5358 49.271C37.0592 49.271 37.4081 49.0965 37.757 48.7476L52.0623 34.4423C52.7601 33.7445 52.7601 32.6977 52.0623 31.9999Z"
        fill="#3EB96F"
      />
    </svg>
  );
}
