'use client';

import { Box, Button, styled } from '@mui/material';

export const StyledPaginationBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(2),
  gap: theme.spacing(2),
}));

export const StyledPaginationNav = styled(Button)(({ theme }) => ({
  color:
    theme.palette.mode === 'light'
      ? theme.palette.grey[500]
      : theme.palette.grey[600],
}));
