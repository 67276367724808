import {
  LocalOfferOutlined,
  MouseOutlined,
  RemoveRedEyeOutlined,
  TrendingUpOutlined,
} from '@mui/icons-material';
import { Box } from '@mui/material';

import { AnunciadoIcon, Card, StyledCardGridContainer } from '~/shared';

import { useGetDashboard } from '../hooks/useGetDashboard';

export type DashCardsProps = {
  getDashboardsQuery: ReturnType<typeof useGetDashboard>;
};

export const DashCards = ({ getDashboardsQuery }: DashCardsProps) => {
  const { data, isLoading } = getDashboardsQuery;

  return (
    <Box>
      <StyledCardGridContainer>
        <>
          <Card
            title="Campanhas"
            iconBackgroundColor="#e0effc"
            iconColor="#0576F0"
            icon={<LocalOfferOutlined />}
            value={data?.totalCampaign ?? '0'}
            isLoading={isLoading}
          />

          <Card
            title="Total anunciado"
            iconBackgroundColor="#ebdff0"
            iconColor="#550084"
            icon={<AnunciadoIcon />}
            value={data?.totalInvestment ?? '0'}
            isLoading={isLoading}
          />

          <Card
            title="Produtos Vendidos"
            iconBackgroundColor="rgba(255,255,255,0.15)"
            iconColor="#FFFFFF"
            icon={<TrendingUpOutlined />}
            value="0"
            isLoading={isLoading}
            sx={theme => ({ backgroundColor: theme.palette.primary.main })}
            titleColor="white"
          />

          <Card
            title="Impressões"
            iconBackgroundColor="#F5E7DE"
            iconColor="#C15702"
            icon={<RemoveRedEyeOutlined />}
            value={data?.impressions ?? '0'}
            isLoading={isLoading}
          />

          <Card
            title="Cliques"
            icon={<MouseOutlined />}
            value={data?.clicks ?? '0'}
            isLoading={isLoading}
          />

          <Card
            title="CTR"
            icon={<MouseOutlined />}
            value={data?.ctr ?? '0%'}
            isLoading={isLoading}
          />

          <Card
            title="CPC"
            icon={<MouseOutlined />}
            value={data?.cpc ?? '0%'}
            isLoading={isLoading}
          />

          <Card
            title="CPM"
            icon={<MouseOutlined />}
            value={data?.cpm ?? '0%'}
            isLoading={isLoading}
          />

          <Card
            title="CPI"
            icon={<MouseOutlined />}
            value={data?.cpi ?? '0%'}
            isLoading={isLoading}
          />
        </>
      </StyledCardGridContainer>
    </Box>
  );
};
