'use client';

import {
  CircularProgress,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { useGetCampaignResultsDetailed } from '~/entities/Campaign/hooks';
import {
  StyledContainer,
  StyledHeaderTableCell,
  StyledTable,
  StyledTableContainer,
} from '~/shared';

import { BannerRow } from './Row';

export function BannersList({
  getCampaignResultsDetailedQuery,
}: {
  getCampaignResultsDetailedQuery: ReturnType<
    typeof useGetCampaignResultsDetailed
  >;
}) {
  return (
    <StyledContainer
      sx={theme => ({
        width: '100%',
        gridColumn: 'span 3',
        borderRadius: '8px',
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('lg')]: { gridColumn: 'span 2' },
        [theme.breakpoints.down('md')]: { gridColumn: 'span 1' },
      })}
    >
      <StyledTableContainer>
        <StyledTable aria-label="Tabela de Banners">
          <TableHead>
            <TableRow>
              <StyledHeaderTableCell sx={{ width: '0' }} align="center" />
              <StyledHeaderTableCell
                sx={{
                  minWidth: '200px',
                  position: 'sticky',
                  left: 0,
                  backgroundColor: 'background.paper',
                }}
              >
                <Typography variant="body2" component="p" fontWeight={500}>
                  Nome / Formato
                </Typography>
              </StyledHeaderTableCell>
              <StyledHeaderTableCell>
                <Typography variant="body2" component="p" fontWeight={500}>
                  Status
                </Typography>
              </StyledHeaderTableCell>
              <StyledHeaderTableCell>
                <Typography variant="body2" component="p" fontWeight={500}>
                  Plataforma
                </Typography>
              </StyledHeaderTableCell>
              <StyledHeaderTableCell>
                <Typography variant="body2" component="p" fontWeight={500}>
                  Impressões
                </Typography>
              </StyledHeaderTableCell>
              <StyledHeaderTableCell>
                <Typography variant="body2" component="p" fontWeight={500}>
                  Cliques
                </Typography>
              </StyledHeaderTableCell>
              <StyledHeaderTableCell>
                <Typography variant="body2" component="p" fontWeight={500}>
                  CTR
                </Typography>
              </StyledHeaderTableCell>
              <StyledHeaderTableCell>
                <Typography variant="body2" component="p" fontWeight={500}>
                  CPM
                </Typography>
              </StyledHeaderTableCell>
              <StyledHeaderTableCell>
                <Typography variant="body2" component="p" fontWeight={500}>
                  CPC
                </Typography>
              </StyledHeaderTableCell>
              <StyledHeaderTableCell>
                <Typography variant="body2" component="p" fontWeight={500}>
                  Andamento
                </Typography>
              </StyledHeaderTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {getCampaignResultsDetailedQuery.data?.map(banner => {
              return <BannerRow key={banner.key} banner={banner} />;
            })}

            {getCampaignResultsDetailedQuery.isLoading && (
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  colSpan={10}
                  align="center"
                >
                  <CircularProgress size={15} /> Carregando
                </TableCell>
              </TableRow>
            )}

            {getCampaignResultsDetailedQuery.data?.length === 0 && (
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  colSpan={10}
                  align="center"
                >
                  Não possui banners
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
    </StyledContainer>
  );
}
