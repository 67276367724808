import type { ThemeOptions } from '@mui/material';

export const lightTheme: Partial<ThemeOptions> = {
  palette: {
    mode: 'light',
    primary: {
      main: '#2B52DD',
      light: '#466aeb',
      dark: '#072ad8',
    },
    text: {
      primary: '#262338',
      secondary: '#6e7191',
    },
  },
  components: {
    MuiTextField: {
      variants: [
        {
          props: { variant: 'filled' },
          style: {
            '.MuiFilledInput-root': {
              backgroundColor: 'rgba(245, 245, 245, 0.5)',
              borderRadius: 0,
            },
          },
        },
      ],
    },
  },
};
