'use client';

import { Autocomplete, CircularProgress, TextField } from '@mui/material';

import type { Advertiser } from '~/types';

import { useGetAdvertisers } from '../hooks/useGetAdvertisers';

export type AdvertisersSelectProps = {
  id?: string;
  onChange: (advertiser: Advertiser | null) => void;
  value: Advertiser | null;
  advertiserError: string | null;
  disabled?: boolean;
};

export function AdvertisersSelect({
  id,
  onChange,
  value,
  advertiserError,
  disabled,
}: AdvertisersSelectProps) {
  const getAdvertisersQuery = useGetAdvertisers({
    count: 500,
    page: 1,
  });

  return (
    <Autocomplete
      id={id ?? 'advertiser-select'}
      options={getAdvertisersQuery.data?.list ?? ([] as Advertiser[])}
      getOptionLabel={option => option.name}
      getOptionKey={option => option.id}
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      disabled={getAdvertisersQuery.isLoading || disabled}
      loading={getAdvertisersQuery.isLoading}
      renderInput={params => (
        <TextField
          {...params}
          variant="filled"
          label="Anunciante"
          error={!!advertiserError}
          helperText={advertiserError}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {getAdvertisersQuery.isLoading ? (
                  <CircularProgress
                    color="inherit"
                    size={25}
                    sx={{ mt: '-18px' }}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      ListboxProps={{
        sx: {
          maxHeight: '300px',
        },
      }}
    />
  );
}
