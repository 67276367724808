import { Typography } from '@mui/material';

export function stringToColor(string: string) {
  let color = '#';
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name?: string, size: number = 28) {
  if (!name) return {};
  const nameSplit = name.split(' ');
  const initials =
    nameSplit.length >= 2 ? nameSplit[0][0] + nameSplit[1][0] : nameSplit[0][0];
  const style = {
    sx: {
      backgroundColor: stringToColor(name),
      width: size,
      height: size,
    },
    children: <Typography sx={{ fontSize: size / 2 }}>{initials}</Typography>,
  };
  return style;
}
