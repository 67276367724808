import { useQuery } from '@tanstack/react-query';

import { serviceApi } from '~/features';
import { CampaignSegmentsModelView } from '~/types';

export const GET_SEGMENTS_QUERY_KEY = 'GET_SEGMENTS_QUERY';

function getSegments() {
  return serviceApi.get<CampaignSegmentsModelView>('/Segmentacao');
}

export function useGetSegments() {
  return useQuery({
    queryKey: [GET_SEGMENTS_QUERY_KEY],
    queryFn: getSegments,
    select: data => data.data,
  });
}
