import { useRouter } from 'next/navigation';
import { TableCell, TableRow } from '@mui/material';

import { StyledIdTableCell, StyledNameTableCell } from '~/shared';
import { type Advertiser } from '~/types';

import { AdvertisersRowAction } from './Actions';

export type AdvertiserRowProps = {
  advertiser: Advertiser;
  onDelete: (advertiser: Advertiser) => void;
  onEdit: (advertiser: Advertiser) => void;
};

export function AdvertiserRow({
  advertiser,
  onDelete,
  onEdit,
}: AdvertiserRowProps) {
  const router = useRouter();

  function handleClick(advertiserUrl: string) {
    router.push(`/campanhas?advertiserUrl=${advertiserUrl}`);
  }
  return (
    <TableRow
      key={advertiser.name}
      sx={{
        '&:first-child td, &:first-child th': {
          paddingTop: theme => theme.spacing(1),
        },
      }}
    >
      <StyledIdTableCell
        component="th"
        scope="row"
        align="center"
        sx={{ padding: 0 }}
      >
        {advertiser.id}
      </StyledIdTableCell>
      <StyledNameTableCell
        component="th"
        scope="row"
        sx={{ padding: 0, cursor: `pointer` }}
        onClick={() => handleClick(advertiser.name)}
      >
        {advertiser.name}
      </StyledNameTableCell>
      <TableCell
        component="th"
        scope="row"
        align="center"
        sx={{
          borderColor: '#D8E0FD',
          padding: 0,
        }}
      >
        <AdvertisersRowAction
          onDelete={() => onDelete(advertiser)}
          onEdit={() => onEdit(advertiser)}
        />
      </TableCell>
    </TableRow>
  );
}
