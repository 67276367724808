'use client';

import { useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Box, TableCell, TableRow, Typography } from '@mui/material';

import { ChampsIcon, PlatformBadge } from '~/shared';
import type { CampaignResultsDetailedModelView } from '~/types';

export function BannerRow({
  banner,
}: {
  banner: CampaignResultsDetailedModelView[number];
}) {
  const [isShowingRowContent, setIsShowingRowContent] = useState(false);

  return (
    <>
      <TableRow
        title={banner.key}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            '.MuiTableCell-body': {
              backgroundColor: theme => theme.palette.grey[100],
            },
          },
          '.MuiTableCell-body': {
            backgroundColor: 'background.paper',
          },
          ...(banner.daysLastWeekBanner?.length === 0
            ? { cursor: 'default' }
            : {}),
        }}
        onClick={() => {
          if (banner.daysLastWeekBanner?.length > 0) {
            setIsShowingRowContent(!isShowingRowContent);
          }
        }}
      >
        <TableCell component="th" scope="row">
          <ExpandMore
            style={{
              ...(banner.daysLastWeekBanner?.length === 0
                ? { opacity: 0 }
                : {}),
              ...(isShowingRowContent ? { transform: 'rotate(180deg)' } : {}),
              transition: 'transform 0.3s',
            }}
          />
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          sx={{
            position: 'sticky',
            left: 0,
          }}
        >
          <Box sx={{ display: 'flex', alignContent: 'center', gap: '10px' }}>
            <Typography
              sx={{ whiteSpace: 'nowrap' }}
              variant="body2"
              color="primary.main"
              fontWeight={500}
              component="p"
            >
              {banner.name}
            </Typography>
            {banner.isBetterBanner && <ChampsIcon color="#FECA3D" />}
          </Box>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography
            variant="body2"
            component="p"
            sx={{
              color: banner.active ? undefined : '#E10770',
              mr: 0.5,
              whiteSpace: 'nowrap',
            }}
          >
            {`${banner.active ? 'Ativo' : 'Inativo'}`}
          </Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <PlatformBadge
            isApp={banner.device === 'APP'}
            isWeb={banner.device === 'DESKTOP'}
          />
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography
            sx={{ whiteSpace: 'nowrap' }}
            variant="body2"
            component="p"
          >
            {banner.impressions}
          </Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography
            sx={{ whiteSpace: 'nowrap' }}
            variant="body2"
            component="p"
          >
            {banner.clicks}
          </Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography
            sx={{ whiteSpace: 'nowrap' }}
            variant="body2"
            component="p"
          >
            {banner.ctr}
          </Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography
            sx={{ whiteSpace: 'nowrap' }}
            variant="body2"
            component="p"
          >
            {banner.cpm}
          </Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography
            sx={{ whiteSpace: 'nowrap' }}
            variant="body2"
            component="p"
          >
            {banner.cpc}
          </Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          -
        </TableCell>
      </TableRow>

      {banner.daysLastWeekBanner.map(day => {
        return (
          <TableRow
            sx={{ ...(!isShowingRowContent ? { display: 'none' } : {}) }}
            key={day.dayWeek}
          >
            <TableCell component="th" scope="row" />
            <TableCell
              component="th"
              scope="row"
              sx={{
                position: 'sticky',
                left: 0,
                backgroundColor: 'background.paper',
              }}
            >
              <Typography
                sx={{ whiteSpace: 'nowrap' }}
                variant="body2"
                component="p"
              >
                {day.dayWeek}
              </Typography>
            </TableCell>
            <TableCell component="th" scope="row" />
            <TableCell component="th" scope="row">
              <PlatformBadge
                isApp={day.device === 'APP'}
                isWeb={day.device === 'DESKTOP'}
              />
            </TableCell>
            <TableCell component="th" scope="row">
              <Typography
                sx={{ whiteSpace: 'nowrap' }}
                variant="body2"
                component="p"
              >
                {day.impressions}
              </Typography>
            </TableCell>
            <TableCell component="th" scope="row">
              <Typography
                sx={{ whiteSpace: 'nowrap' }}
                variant="body2"
                component="p"
              >
                {day.clicks}
              </Typography>
            </TableCell>
            <TableCell component="th" scope="row">
              <Typography
                sx={{ whiteSpace: 'nowrap' }}
                variant="body2"
                component="p"
              >
                {day.ctr}
              </Typography>
            </TableCell>
            <TableCell component="th" scope="row">
              <Typography
                sx={{ whiteSpace: 'nowrap' }}
                variant="body2"
                component="p"
              >
                {day.cpm}
              </Typography>
            </TableCell>
            <TableCell component="th" scope="row">
              <Typography
                sx={{ whiteSpace: 'nowrap' }}
                variant="body2"
                component="p"
              >
                {day.cpc}
              </Typography>
            </TableCell>
            <TableCell component="th" scope="row">
              -
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
}
