'use client';

import { forwardRef } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { TextField, type TextFieldProps } from '@mui/material';

interface CustomNumericCurrencyFormatProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const CustomNumericCurrencyFormat = forwardRef<
  NumericFormatProps,
  CustomNumericCurrencyFormatProps
>(function NumericFormatCustom({ onChange, name, ...other }, ref) {
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      valueIsNumericString
      prefix="R$"
    />
  );
});

export const CurrencyTextField = forwardRef<
  HTMLInputElement,
  Omit<TextFieldProps, 'InputProps' | 'inputRef'> & {
    withIcon?: React.ReactNode;
  }
>(({ withIcon, ...props }, ref) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      InputProps={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: CustomNumericCurrencyFormat as any,
        startAdornment: withIcon,
      }}
    />
  );
});

CurrencyTextField.displayName = 'CurrencyTextField';
