'use client';

import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { CampaignStatusText, Dialog, StatusBadge } from '~/shared';
import { CampaignStatus } from '~/types';

import { GET_CAMPAIGN_QUERY_KEY, GET_CAMPAIGNS_QUERY_KEY } from '../hooks';
import { useUpdateCampaign } from '../hooks/useUpdateCampaign';

export type StatusSelectBadgeProps = {
  campaignId: string;
  campaignStatus: `${CampaignStatus}`;
};

const notImplemented = ['ATTENTION', 'REVIEW'];

export function StatusSelectBadge({
  campaignId,
  campaignStatus,
}: StatusSelectBadgeProps) {
  const published = campaignStatus !== 'DRAFT';

  const queryClient = useQueryClient();
  const [status, setStatus] = useState<`${CampaignStatus}`>(campaignStatus);

  const [isPublishing, setIsPublishing] = useState(false);
  const [isPublishingSuccess, setIsPublishingSuccess] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateCampaignQuery = useUpdateCampaign(({ data }) => {
    if (data.status) {
      setStatus(data?.status as `${CampaignStatus}`);
      queryClient.invalidateQueries({
        queryKey: [GET_CAMPAIGN_QUERY_KEY, data?.id],
      });
      queryClient.invalidateQueries({
        queryKey: [GET_CAMPAIGNS_QUERY_KEY],
      });
      handleClose();
    }
  });

  function handleStatusChange(newStatus: `${CampaignStatus}`) {
    updateCampaignQuery.mutate({
      id: campaignId,
      status: newStatus,
    });
  }

  function handlePublish() {
    setIsPublishing(true);
  }

  useEffect(() => {
    setStatus(campaignStatus);
  }, [campaignStatus]);

  return (
    <Box
      sx={{ pointerEvents: updateCampaignQuery.isPending ? 'none' : 'auto' }}
    >
      <StatusBadge
        status={status}
        withBorder
        withDetail
        withSelector
        onClick={handleClick}
        open={open || updateCampaignQuery.isPending}
        id="campaign-status-select-badge"
        isLoading={updateCampaignQuery.isPending}
      />
      <Menu
        id="campaign-status-select-menu"
        aria-labelledby="campaign-status-select-badge"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ mt: 1 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {Object.values(CampaignStatus).map(
          s =>
            !notImplemented.includes(s) &&
            published &&
            s !== 'DRAFT' && (
              <MenuItem
                key={s}
                onClick={() => handleStatusChange(s)}
                disabled={s === status || updateCampaignQuery.isPending}
              >
                {CampaignStatusText[s]}
              </MenuItem>
            )
        )}

        {!published && [
          <MenuItem key="draft" disabled>
            Rascunho
          </MenuItem>,
          <MenuItem
            key="publish"
            onClick={() => handlePublish()}
            disabled={updateCampaignQuery.isPending}
          >
            Publicar Campanha
          </MenuItem>,
        ]}
      </Menu>

      {/** TODO: Extract this as a Campaign Dialog */}
      {isPublishing && (
        <Dialog withCloseButton={false} open>
          {!updateCampaignQuery.isPending ? (
            <Typography
              sx={{ m: 0, color: 'primary.main', textAlign: 'center' }}
              variant="h4"
            >
              Você esta prestes a publicar a campanha!
            </Typography>
          ) : (
            <Typography
              sx={{ m: 0, color: 'primary.main', textAlign: 'center' }}
              variant="h4"
            >
              Publicando <CircularProgress size={15} />
            </Typography>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 4, gap: 2 }}>
            <Button
              disabled={updateCampaignQuery.isPending}
              size="large"
              variant="outlined"
              sx={{ px: 3, py: 1.5 }}
              onClick={() => {
                setIsPublishing(false);
                handleClose();
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={updateCampaignQuery.isPending}
              size="large"
              variant="contained"
              sx={{ px: 3, py: 1.5 }}
              onClick={() => {
                updateCampaignQuery.mutate(
                  {
                    id: campaignId,
                    status: 'ACTIVE',
                  },
                  {
                    onSuccess: () => {
                      setIsPublishing(false);
                      setIsPublishingSuccess(true);
                    },
                  }
                );
              }}
            >
              Publicar
            </Button>
          </Box>

          <Typography
            sx={{
              fontStyle: 'italic',
              p: 2,
              fontSize: '14px',
              textAlign: 'center',
            }}
          >
            Lembrando que você pode alterar o status da campanha a qualquer
            momento depois sem perder os dados de performance.
          </Typography>
        </Dialog>
      )}

      {isPublishingSuccess && (
        <Dialog withCloseButton={false} open>
          <Typography
            sx={{ m: 0, color: 'primary.main', textAlign: 'center' }}
            variant="h4"
          >
            Campanha publicada com sucesso!
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 4, gap: 2 }}>
            <Button
              size="large"
              variant="contained"
              sx={{ px: 3, py: 1.5 }}
              onClick={() => {
                setIsPublishingSuccess(false);
              }}
            >
              Continuar
            </Button>
          </Box>
        </Dialog>
      )}
    </Box>
  );
}
