'use client';

import { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

import { Dialog, RedX } from '~/shared';
import type { Advertiser } from '~/types';

import { useUpdateAdvertiser } from '../hooks';

export type DeleteAdvertiserProps = {
  open: boolean;
  onClose?: () => void;
  onCloseTransitionEnded?: () => void;
  selectedAdvertiser: Advertiser | null;
  refetchList: () => void;
};

export function DeleteAdvertiserDialog({
  open,
  selectedAdvertiser,
  onClose,
  onCloseTransitionEnded,
  refetchList,
}: DeleteAdvertiserProps) {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const useDeleteAdvertiserMutation = useUpdateAdvertiser(() => {
    refetchList();
    setIsSubmitSuccess(true);
  });

  function handleDelete() {
    if (!selectedAdvertiser) return;
    useDeleteAdvertiserMutation.mutate({
      id: selectedAdvertiser.id,
      deleted: true,
    });
  }

  const dialogTitle = isSubmitSuccess
    ? 'Anunciante excluído com sucesso'
    : 'Tem certeza que deseja excluir o anunciante?';

  return (
    <Dialog
      title={dialogTitle}
      withIcon={<RedX size={70} />}
      open={open}
      onClose={() => {
        if (useDeleteAdvertiserMutation.isPending) return;
        onClose?.();
        /** state was not updating correctly after transition */
        setTimeout(() => {
          useDeleteAdvertiserMutation.reset();
          setIsSubmitSuccess(false);
        }, 300);
      }}
      onCloseTransitionEnded={() => {
        setIsSubmitSuccess(false);
        useDeleteAdvertiserMutation.reset();
        onCloseTransitionEnded?.();
      }}
    >
      {!isSubmitSuccess && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
              py: 2,
              backgroundColor: '#FFEBEE',
              border: '1px solid #E73933',
            }}
          >
            <Typography
              sx={{
                fontSize: theme => theme.typography.pxToRem(20),
                fontWeight: 500,
              }}
            >
              {selectedAdvertiser?.name}
            </Typography>
          </Box>
          <Typography
            sx={{
              mt: 2,
              fontSize: theme => theme.typography.pxToRem(16),
              color: theme => theme.palette.text.secondary,
            }}
          >
            Todos os dados cadastrados serão perdidos.
          </Typography>

          <Box sx={{ display: 'flex', gap: 3, mt: 3 }}>
            <Button
              variant="outlined"
              size="small"
              sx={{ py: 2 }}
              fullWidth
              disabled={useDeleteAdvertiserMutation.isPending}
              onClick={() => {
                if (useDeleteAdvertiserMutation.isPending) return;
                onClose?.();
                /** state was not updating correctly after transition */
                setTimeout(() => {
                  useDeleteAdvertiserMutation.reset();
                  setIsSubmitSuccess(false);
                }, 300);
              }}
            >
              Voltar
            </Button>
            <Button
              variant="contained"
              size="small"
              color="error"
              sx={{ py: 2 }}
              fullWidth
              disabled={useDeleteAdvertiserMutation.isPending}
              onClick={handleDelete}
            >
              Excluir
            </Button>
          </Box>
          {useDeleteAdvertiserMutation.isError && (
            <Typography
              sx={{
                mt: 2,
                fontSize: theme => theme.typography.pxToRem(16),
                color: theme => theme.palette.error.light,
              }}
            >
              {useDeleteAdvertiserMutation.error?.message}
            </Typography>
          )}
        </>
      )}

      {isSubmitSuccess && (
        <Button
          variant="outlined"
          size="small"
          sx={{ py: 2 }}
          fullWidth
          onClick={() => {
            if (useDeleteAdvertiserMutation.isPending) return;
            onClose?.();
            /** state was not updating correctly after transition */
            setTimeout(() => {
              useDeleteAdvertiserMutation.reset();
              setIsSubmitSuccess(false);
            }, 300);
          }}
        >
          Ver Anunciantes
        </Button>
      )}
    </Dialog>
  );
}
