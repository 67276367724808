import { CustomIconsProps } from './types';

export function RedX({ size = 24 }: Omit<CustomIconsProps, 'color'>) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="40" cy="40" r="40" fill="#FFEBEE" />
      <path
        d="M42.8337 40.3738L50.3353 32.8897C50.6638 32.5612 50.8483 32.1157 50.8483 31.6511C50.8483 31.1865 50.6638 30.741 50.3353 30.4125C50.0068 30.084 49.5612 29.8994 49.0966 29.8994C48.6321 29.8994 48.1865 30.084 47.858 30.4125L40.3739 37.914L32.8898 30.4125C32.5613 30.084 32.1157 29.8994 31.6511 29.8994C31.1866 29.8994 30.741 30.084 30.4125 30.4125C30.084 30.741 29.8995 31.1865 29.8995 31.6511C29.8995 32.1157 30.084 32.5612 30.4125 32.8897L37.9141 40.3738L30.4125 47.858C30.249 48.0201 30.1192 48.2131 30.0307 48.4257C29.9421 48.6383 29.8965 48.8663 29.8965 49.0966C29.8965 49.3269 29.9421 49.5549 30.0307 49.7675C30.1192 49.9801 30.249 50.173 30.4125 50.3352C30.5747 50.4987 30.7676 50.6285 30.9802 50.7171C31.1928 50.8056 31.4208 50.8512 31.6511 50.8512C31.8814 50.8512 32.1095 50.8056 32.3221 50.7171C32.5347 50.6285 32.7276 50.4987 32.8898 50.3352L40.3739 42.8337L47.858 50.3352C48.0202 50.4987 48.2131 50.6285 48.4257 50.7171C48.6383 50.8056 48.8663 50.8512 49.0966 50.8512C49.3269 50.8512 49.555 50.8056 49.7675 50.7171C49.9801 50.6285 50.1731 50.4987 50.3353 50.3352C50.4988 50.173 50.6286 49.9801 50.7171 49.7675C50.8057 49.5549 50.8513 49.3269 50.8513 49.0966C50.8513 48.8663 50.8057 48.6383 50.7171 48.4257C50.6286 48.2131 50.4988 48.0201 50.3353 47.858L42.8337 40.3738Z"
        fill="#E73933"
      />
    </svg>
  );
}
