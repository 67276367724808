import { type CallbacksOptions } from 'next-auth';

import { AuthProviders } from '../types';

export const singInAuthHandler: CallbacksOptions['signIn'] = ({ user }) => {
  return !!user;
};

export const jwtAuthHandler: CallbacksOptions['jwt'] = ({
  user,
  account,
  token,
}) => {
  const returnToken = token;
  const isMartins = account?.provider === AuthProviders.MARTINS_CRED;
  const isRefreshToken = account?.provider === AuthProviders.REFRESH_TOKEN;
  if (isMartins && user.token) {
    returnToken.token = user.token;
  }
  if (isMartins || isRefreshToken) {
    returnToken.token = user.token;
    returnToken.serviceToken = user.serviceToken;
    returnToken.refreshToken = user.refreshToken;
    returnToken.registration = user.registration;
  }

  return token;
};

export const sessionAuthHandler: CallbacksOptions['session'] = ({
  session,
  token,
}) => {
  const returnSession = { ...session };

  if (token) {
    returnSession.t = {
      // mt: token.token ? token.token : returnSession.t?.mt,
      st: token.serviceToken,
      rt: token.refreshToken,
    };
    returnSession.user = {
      ...returnSession.user,
      registration: token.registration
        ? token.registration
        : returnSession.user?.registration,
    };
  }

  return returnSession;
};
