import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';

export function ProcessedDate({ date }: { date: Date }) {
  try {
    return (
      <Box
        sx={{ textAlign: 'left', margin: '0 auto', display: 'inline-block' }}
      >
        <Typography sx={{ color: '#626161', fontWeight: 400 }} variant="body2">
          {format(date, 'dd/MM/yyyy')}
        </Typography>
        <Typography sx={{ color: '#BCBBBB' }} variant="body2">
          às {format(date, 'HH:mm')}
        </Typography>
      </Box>
    );
  } catch (error) {
    console.error('Error parsing date', error);
    return null;
  }
}
