export function clearParams(params: Record<string, unknown>) {
  return Object.entries(params).reduce<Record<string, unknown>>(
    (acc, [key, value]) => {
      if (value !== undefined && value !== null && value !== '' && key) {
        acc[key] = value;
      }

      return acc;
    },
    {}
  );
}
