'use client';

import { styled, Switch, type SwitchProps } from '@mui/material';

export const DefaultSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  padding: 8,
  marginTop: -5,

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        border: `2px solid ${theme.palette.primary.main}`,
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.grey[100],
      },
    },
  },

  '& .MuiSwitch-track': {
    borderRadius: '200px',
    border: `2px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[400] : theme.palette.grey[800]}`,
    opacity: 1,
    backgroundColor: 'transparent',
  },

  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 18,
    height: 18,
    margin: 3,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.grey[400]
        : theme.palette.grey[800],
  },
}));
