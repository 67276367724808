import { useQuery, useQueryClient } from '@tanstack/react-query';

import { serviceApi } from '~/features';
import { clearParams } from '~/shared';
import type { AdvertiserListDTO, AdvertiserListModelView } from '~/types';

export const GET_ADVERTISERS_QUERY_KEY = 'GET_ADVERTISERS_QUERY';

async function getAdvertisers(params: AdvertiserListDTO) {
  const clearedParams = clearParams(params) as AdvertiserListDTO;

  const response = serviceApi.get<AdvertiserListModelView>(`/Anunciante/List`, {
    params: clearedParams,
  });

  return response;
}

export function useGetAdvertisers(params: AdvertiserListDTO) {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [GET_ADVERTISERS_QUERY_KEY, params],
    select: data => data?.data,
    queryFn: () => getAdvertisers(params),
  });

  return Object.assign(query, {
    refetchAll: () =>
      queryClient.refetchQueries({ queryKey: [GET_ADVERTISERS_QUERY_KEY] }),
  });
}
