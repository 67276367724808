import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { serviceApi } from '~/features';
import { ReportsCampaignsDto } from '~/types';

export const DOWNLOAD_REPORTS_CAMPAIGNS_MUTATION_KEY =
  'DOWNLOAD_REPORTS_CAMPAIGNS_MUTATION';

async function exportExcel(params: ReportsCampaignsDto) {
  return serviceApi.get<Blob>('/Report/Campaigns-Export', {
    params,
    responseType: 'blob',
  });
}

export function useDownloadReportsCampaigns(
  onSuccess?: (
    data: AxiosResponse<Blob, unknown>,
    variables: ReportsCampaignsDto,
    context: ReportsCampaignsDto
  ) => unknown,
  onError?: (
    error: Error,
    variables: ReportsCampaignsDto,
    context: ReportsCampaignsDto | undefined
  ) => unknown
) {
  return useMutation({
    mutationKey: [DOWNLOAD_REPORTS_CAMPAIGNS_MUTATION_KEY],
    mutationFn: async (params: ReportsCampaignsDto) => exportExcel(params),
    onSuccess,
    onError,
  });
}
