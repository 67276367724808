'use client';

import { Box, Typography } from '@mui/material';

export function ProcessedName({
  name,
  advertiserName,
}: {
  name: string;
  advertiserName?: string;
}) {
  return (
    <Box>
      {advertiserName && (
        <Typography variant="body2" color="#626161">
          {advertiserName}
        </Typography>
      )}
      <Typography color="primary.main" fontWeight={500}>
        {name}
      </Typography>
    </Box>
  );
}
