import { Button, type ButtonProps } from '@mui/material';

export function ActionButton({
  startIcon = null,
  variant = 'contained',
  size = 'medium',
  color = 'primary',
  sx,
  ...props
}: ButtonProps) {
  return (
    <Button
      startIcon={startIcon}
      variant={variant ?? 'contained'}
      size={size ?? 'medium'}
      color={color ?? 'primary'}
      sx={{
        textTransform: 'none',
        boxShadow: 'none',
        px: 4,
        ...sx,
      }}
      {...props}
    />
  );
}
