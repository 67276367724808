'use client';

import {
  Box,
  CircularProgress,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { stickNameCell, StyledHeaderTableCell, StyledTable } from '~/shared';
import { ReportsFilterModelView } from '~/types';
import { ReportsFilterResumeModelView } from '~/types/reports/filterResume';

import { ReportAdvertisersRow } from './Row';

export type ReportAdvertisersListProps = {
  data: ReportsFilterModelView | null;
  resumeData: ReportsFilterResumeModelView | null;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
};

function TitleDescription({
  title,
  description,
  center,
}: {
  title: string;
  description: string;
  center?: boolean;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...(center ? { alignItems: 'center', textAlign: 'center' } : {}),
      }}
    >
      <Box sx={{ display: 'inline-block', width: 'min-content' }}>
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            color: '#4E4B66',
            fontWeight: 'bold',
          }}
        >
          {title}
        </Typography>
        <Typography sx={{ whiteSpace: 'nowrap', color: '#4E4B66' }}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
}

export function ReportAdvertisersList({
  data,
  resumeData,
  isLoading,
  isError,
  error,
}: ReportAdvertisersListProps) {
  return (
    <StyledTable aria-label="Tabela de Relatórios">
      <TableHead>
        <TableRow sx={{ border: '1px dashed #BDBDBD' }}>
          <StyledHeaderTableCell
            sx={{ width: '60px', border: 'none' }}
            align="center"
          />
          <StyledHeaderTableCell sx={{ ...stickNameCell(), border: 'none' }}>
            <TitleDescription
              title="Total de Linhas"
              description={resumeData?.total ? `${resumeData.total}` : '-'}
              center={false}
            />
          </StyledHeaderTableCell>
          <StyledHeaderTableCell sx={{ border: 'none' }}>
            <TitleDescription
              title="Impressões"
              description={resumeData?.impressions ?? '-'}
            />
          </StyledHeaderTableCell>
          <StyledHeaderTableCell sx={{ border: 'none' }}>
            <TitleDescription
              title="Cliques"
              description={resumeData?.clicks ?? '-'}
            />
          </StyledHeaderTableCell>
          <StyledHeaderTableCell sx={{ border: 'none' }}>
            <TitleDescription
              title="CTR"
              description={resumeData?.ctr ?? '-'}
            />
          </StyledHeaderTableCell>
          <StyledHeaderTableCell sx={{ border: 'none' }}>
            <TitleDescription
              title="CPM"
              description={resumeData?.cpm ?? '-'}
            />
          </StyledHeaderTableCell>
          <StyledHeaderTableCell sx={{ border: 'none' }}>
            <TitleDescription
              title="CPC"
              description={resumeData?.cpc ?? '-'}
            />
          </StyledHeaderTableCell>
          <StyledHeaderTableCell sx={{ border: 'none' }} />
          <StyledHeaderTableCell sx={{ border: 'none' }}>
            <TitleDescription
              title="Total Investido"
              description={resumeData?.totalInvestment ?? '-'}
            />
          </StyledHeaderTableCell>
          <StyledHeaderTableCell sx={{ border: 'none' }} />
        </TableRow>

        <TableRow>
          <StyledHeaderTableCell sx={{ width: '60px' }} align="center">
            ID
          </StyledHeaderTableCell>
          <StyledHeaderTableCell sx={stickNameCell()}>
            Nome do Anunciante
          </StyledHeaderTableCell>
          <StyledHeaderTableCell>Impressões</StyledHeaderTableCell>
          <StyledHeaderTableCell>Cliques</StyledHeaderTableCell>
          <StyledHeaderTableCell>CTR</StyledHeaderTableCell>
          <StyledHeaderTableCell>CPM</StyledHeaderTableCell>
          <StyledHeaderTableCell>CPC</StyledHeaderTableCell>
          <StyledHeaderTableCell>Local</StyledHeaderTableCell>
          <StyledHeaderTableCell>Investimento</StyledHeaderTableCell>
          {/* <StyledHeaderTableCell align="center">Download</StyledHeaderTableCell> */}
        </TableRow>
      </TableHead>

      <TableBody>
        {isLoading && (
          <TableRow>
            <TableCell component="th" scope="row" colSpan={10} align="center">
              <CircularProgress size={15} /> Carregando
            </TableCell>
          </TableRow>
        )}

        {!isLoading && !isError && data?.anuncianteResponse?.length === 0 && (
          <TableRow>
            <TableCell colSpan={10} align="center">
              Sem resultados
            </TableCell>
          </TableRow>
        )}

        {isError && (
          <TableRow>
            <TableCell
              sx={{ color: theme => theme.palette.error.light }}
              colSpan={10}
              align="center"
            >
              <Typography>Ocorreu um erro: {error?.name}</Typography>
              <Typography>{error?.message}</Typography>
            </TableCell>
          </TableRow>
        )}

        {!isLoading &&
          !isError &&
          data?.anuncianteResponse?.map(resume => {
            return (
              <ReportAdvertisersRow
                key={resume.advertiser.id}
                resume={resume}
              />
            );
          })}
      </TableBody>
    </StyledTable>
  );
}
