import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import sanitize from 'sanitize-html';

import { serviceApi } from '~/features';
import type { CreateCampaignDto, CreateCampaignModelView } from '~/types';

export const CREATE_CAMPAIGN_MUTATION_KEY = 'CREATE_CAMPAIGN_MUTATION';

async function createCampaign(body: CreateCampaignDto) {
  return serviceApi.post<CreateCampaignModelView>('/Campanha', {
    ...body,
    name: sanitize(body.name),
  });
}

export function useCreateCampaign(
  onSuccess?: (
    data: AxiosResponse<CreateCampaignModelView, unknown>,
    variables: CreateCampaignDto,
    context: unknown
  ) => unknown,
  onError?: (
    error: Error,
    variables: CreateCampaignDto,
    context: unknown
  ) => unknown
) {
  return useMutation({
    mutationKey: [CREATE_CAMPAIGN_MUTATION_KEY],
    mutationFn: async (body: CreateCampaignDto) => createCampaign(body),
    onSuccess,
    onError,
  });
}
