import { CircularProgress, Typography } from '@mui/material';

import { AuthProviders } from '~/features/Auth/types';

import { StyledLoadingBox } from './elements';

const LoginMap = {
  [AuthProviders.MARTINS_CRED]: 'Credencial Martins',
  [AuthProviders.REFRESH_TOKEN]: 'Refresh Token',
  [AuthProviders.EXPIRED_MARTINS_CRED]: 'Credencial Martins Expirada',
  // [AuthProviders.AZURE_AD]: 'Azure AD',
  // [AuthProviders.MOCK_CRED]: 'Credential Mocked',
};

export function LoadingSplashScreen({
  isSubmitting,
}: {
  isSubmitting: `${AuthProviders}` | null;
}) {
  const provider = isSubmitting ? LoginMap[isSubmitting] : '';

  return (
    <StyledLoadingBox>
      <CircularProgress />
      <Typography variant="body2" sx={{ marginLeft: 1 }}>
        Fazendo login com {provider}
      </Typography>
    </StyledLoadingBox>
  );
}
