'use client';

import {
  CircularProgress,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { stickNameCell, StyledHeaderTableCell, StyledTable } from '~/shared';

import { useGetReportsCampaigns } from '../../hooks';

import { ReportCampaignsRow } from './Row';

export type ReportCampaignsListProps = {
  getReportsCampaignsQuery: ReturnType<typeof useGetReportsCampaigns>;
  advertiserId: string;
};

export function ReportCampaignsList({
  getReportsCampaignsQuery,
  advertiserId,
}: ReportCampaignsListProps) {
  const { data, isLoading, isError, error } = getReportsCampaignsQuery;

  return (
    <StyledTable aria-label="Tabela de Relatórios">
      <TableHead>
        <TableRow>
          <StyledHeaderTableCell sx={{ width: '60px' }} align="center">
            ID
          </StyledHeaderTableCell>
          <StyledHeaderTableCell sx={stickNameCell()}>
            Campanha
          </StyledHeaderTableCell>
          <StyledHeaderTableCell>Plataforma</StyledHeaderTableCell>
          <StyledHeaderTableCell>Impressões</StyledHeaderTableCell>
          <StyledHeaderTableCell>Cliques</StyledHeaderTableCell>
          <StyledHeaderTableCell>CTR</StyledHeaderTableCell>
          <StyledHeaderTableCell>CPM</StyledHeaderTableCell>
          <StyledHeaderTableCell>CPC</StyledHeaderTableCell>
          {/* <StyledHeaderTableCell align="center">Download</StyledHeaderTableCell> */}
        </TableRow>
      </TableHead>

      <TableBody>
        {isLoading && (
          <TableRow>
            <TableCell component="th" scope="row" colSpan={9} align="center">
              <CircularProgress size={15} /> Carregando
            </TableCell>
          </TableRow>
        )}

        {!isLoading && !isError && data?.list?.length === 0 && (
          <TableRow>
            <TableCell colSpan={9} align="center">
              Sem resultados
            </TableCell>
          </TableRow>
        )}

        {isError && (
          <TableRow>
            <TableCell
              sx={{ color: theme => theme.palette.error.light }}
              colSpan={9}
              align="center"
            >
              <Typography>Ocorreu um erro: {error.name}</Typography>
              <Typography>{error.message}</Typography>
            </TableCell>
          </TableRow>
        )}

        {!isLoading &&
          !isError &&
          data?.list?.map(resume => {
            return (
              <ReportCampaignsRow
                key={resume.idCampaign}
                resume={resume}
                advertiserId={advertiserId}
              />
            );
          })}
      </TableBody>
    </StyledTable>
  );
}
