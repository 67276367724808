export type MartinsAuthResponse = {
  codigo: number;
  autenticado: boolean;
  token: string | null;
  mensagem: string | null;
};

export const MartinsAuthError = {
  '1': 'INVALID_CREDENTIALS',
  '2': 'LDAP_NOT_REACHED',
  '3': 'EXPIRED_ACCESS',
  '4': 'USER_NOT_AUTHORIZED',
  '5': 'USER_NOT_IDENTIFIED',
  '6': 'REPOSITORY_EXCEPTION',
  '7': 'JWT_NOT_GENERATED',
  '8': 'CLIENT_API_NOT_FOUND',
  '12': 'UNKNOWN_EXCEPTION',
  '13': 'JWT_SECRET_NOT_FOUND',
  '14': 'JWT_GENERATION_EXCEPTION',
  '15': 'MISSING_CREDENTIALS',
  '16': 'ENVOIRMENT_MISCONFIGURED',
  '17': 'SERVICE_UNAVAILABLE',
};
