'use client';

import { Box, styled, Typography } from '@mui/material';

export const StyledContentTitleBox = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
  alignItems: 'center',
  width: '95%',
  maxWidth: theme.breakpoints.values.xl + 300,
  margin: `0 auto`,
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
}));

export const StyledTitleContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(28),
  fontWeight: 500,
}));
