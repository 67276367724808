import { useRouter } from 'next/navigation';
import { TableCell, TableRow } from '@mui/material';

// import { format } from 'date-fns';
import { useGetBannersKeys } from '~/entities/Banners';
import { ProcessedName } from '~/shared';
import { CampaignBannerReport } from '~/types';

// import { useDownloadReportsBanner } from '../../hooks';

export type ReportCampaignRowProps = {
  resume: CampaignBannerReport;
  advertiserId: string;
  campaignId: string;
  // campaignName?: string;
};

export function ReportCampaignRow({
  resume,
  advertiserId,
  campaignId,
  // campaignName,
}: ReportCampaignRowProps) {
  const router = useRouter();
  const getBannersKeys = useGetBannersKeys();

  const bannerKey = getBannersKeys.data?.options.find(
    option => option.name === resume.name
  );

  function handleClick() {
    if (!bannerKey) return;
    router.push(`/relatorios/${advertiserId}/${campaignId}/${bannerKey.key}`);
  }

  // const downloadReportsBannerMutation = useDownloadReportsBanner(res => {
  //   const nowFormatted = format(new Date(), 'dd-MM-yyyy HH-mm');
  //   const filename = `Relatório Banner - ${bannerKey?.name} - ${campaignName ?? 'Sem Nome'} - ${nowFormatted}.xlsx`;
  //   const blob = new Blob([res.data], { type: res.headers['content-type'] });
  //   const downloadUrl = window.URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.href = downloadUrl;
  //   a.download = filename;
  //   a.click();
  // });

  // function handleExportExcel() {
  //   downloadReportsBannerMutation.mutate({
  //     idCampaign: campaignId,
  //     key: bannerKey?.key ?? '',
  //     count: 9999,
  //     page: 1,
  //   });
  // }

  return (
    <TableRow
      key={`${advertiserId}_${campaignId}`}
      sx={{
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        '&:hover': {
          '.MuiTableCell-body': {
            backgroundColor: theme => theme.palette.grey[100],
          },
        },
        '.MuiTableCell-body': {
          backgroundColor: 'background.paper',
        },
      }}
    >
      <TableCell
        component="th"
        scope="row"
        sx={{ position: 'sticky', left: 0 }}
        onClick={handleClick}
      >
        <ProcessedName name={resume.name} />
      </TableCell>
      <TableCell component="th" scope="row" onClick={handleClick}>
        {resume.device ?? '-'}
      </TableCell>
      <TableCell component="th" scope="row" onClick={handleClick}>
        {resume.impressions ?? '-'}
      </TableCell>
      <TableCell component="th" scope="row" onClick={handleClick}>
        {resume.clicks ?? '-'}
      </TableCell>
      <TableCell component="th" scope="row" onClick={handleClick}>
        {resume.ctr ?? '-'}
      </TableCell>
      <TableCell component="th" scope="row" onClick={handleClick}>
        {resume.cpm ?? '-'}
      </TableCell>
      <TableCell component="th" scope="row" onClick={handleClick}>
        {resume.cpc ?? '-'}
      </TableCell>
      {/* <TableCell component="th" scope="row" align="center">
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={
            downloadReportsBannerMutation.isPending ? (
              <CircularProgress size={15} />
            ) : (
              <SaveAltSharp />
            )
          }
          sx={{ textTransform: 'none' }}
          onClick={handleExportExcel}
          disabled={downloadReportsBannerMutation.isPending}
        >
          Baixar
        </Button>
      </TableCell> */}
    </TableRow>
  );
}
