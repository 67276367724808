'use client';

import { type Dispatch, type SetStateAction } from 'react';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@mui/icons-material/';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  type SelectChangeEvent,
} from '@mui/material';

import { CampaignStatus } from '~/types';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CAMPAIGN_STATUS: { key: `${CampaignStatus}`; name: string }[] = [
  { key: 'ACTIVE', name: 'Ativa' },
  { key: 'INACTIVE', name: 'Inativa' },
  { key: 'DRAFT', name: 'Rascunho' },
  { key: 'REVIEW', name: 'Em Aprovação' },
  { key: 'DONE', name: 'Concluída' },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MENU_PROPS = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 9.8 + ITEM_PADDING_TOP,
    },
  },
};

export function StatusSelect({
  selectedStatus,
  setter,
  id,
  label,
  growAtLg,
  fullWidth,
  maxWidth,
}: {
  selectedStatus: '' | `${CampaignStatus}`;
  setter: Dispatch<SetStateAction<'' | `${CampaignStatus}`>>;
  id?: string;
  label?: string;
  growAtLg?: boolean;
  fullWidth?: boolean;
  maxWidth?: string;
}) {
  const handleChange = ({ target }: SelectChangeEvent<`${CampaignStatus}`>) => {
    const { value } = target;
    setter(value === selectedStatus ? '' : (value as `${CampaignStatus}`));
  };

  const labelSelect = label ?? 'Status';

  return (
    <FormControl
      variant="filled"
      fullWidth={fullWidth}
      sx={theme => ({
        ...(maxWidth && { maxWidth }),
        ...(growAtLg && {
          [theme.breakpoints.down('lg')]: { maxWidth: '100%' },
        }),
      })}
    >
      <InputLabel id="campaign-status-select-label">{labelSelect}</InputLabel>
      <Select
        label={labelSelect}
        placeholder={labelSelect}
        labelId="campaign-status-select-label"
        id={id ?? 'weekdays-select'}
        onChange={handleChange}
        value={selectedStatus}
        sx={{ borderRadius: 0 }}
        renderValue={selected =>
          CAMPAIGN_STATUS.find(status => status.key === selected)?.name ?? ''
        }
        MenuProps={MENU_PROPS}
      >
        <MenuItem value={selectedStatus} sx={{ display: 'none' }} />
        {CAMPAIGN_STATUS.map(status => (
          <MenuItem
            key={status.key}
            value={status.key === selectedStatus ? '' : status.key}
          >
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selectedStatus === status.key}
            />
            <ListItemText primary={status.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
