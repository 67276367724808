import { useRouter } from 'next/navigation';
import { TableCell, TableRow, Typography } from '@mui/material';

import { ProcessedName, StyledIdTableCell } from '~/shared';
import { DashCampaignsModelView } from '~/types';

export type DashListRowProps = {
  campaign: DashCampaignsModelView['bestCampaigns'][number];
};

export function DashListRow({ campaign }: DashListRowProps) {
  const router = useRouter();

  return (
    <TableRow
      key={campaign.campaignName}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          '.MuiTableCell-body': {
            backgroundColor: theme => theme.palette.grey[100],
          },
        },
        '.MuiTableCell-body': {
          backgroundColor: 'background.paper',
        },
      }}
    >
      <StyledIdTableCell
        component="th"
        scope="row"
        align="center"
        onClick={() => router.push(`/campanhas/${campaign.id}`)}
      >
        {campaign.id}
      </StyledIdTableCell>
      <TableCell
        component="th"
        scope="row"
        sx={{
          position: 'sticky',
          left: 0,
        }}
        onClick={() => router.push(`/campanhas/${campaign.id}`)}
      >
        <ProcessedName
          name={campaign.campaignName}
          advertiserName={campaign?.advertiserName}
        />
      </TableCell>
      <TableCell component="th" scope="row" align="center">
        <Typography sx={{ whiteSpace: 'nowrap' }} variant="body2" component="p">
          {campaign.impressions ?? 0}
        </Typography>
      </TableCell>
      <TableCell component="th" scope="row" align="center">
        <Typography sx={{ whiteSpace: 'nowrap' }} variant="body2" component="p">
          {campaign.clicks ?? 0}
        </Typography>
      </TableCell>
      <TableCell component="th" scope="row" align="center">
        <Typography sx={{ whiteSpace: 'nowrap' }} variant="body2" component="p">
          {campaign.ctr ?? 0}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
