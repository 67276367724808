'use client';

import {
  CircularProgress,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { stickNameCell, StyledHeaderTableCell, StyledTable } from '~/shared';

import { useGetReportsCampaign } from '../../hooks';

import { ReportCampaignRow } from './Row';

export type ReportCampaignListProps = {
  getReportsCampaignQuery: ReturnType<typeof useGetReportsCampaign>;
  advertiserId: string;
  campaignId: string;
  // campaignName?: string;
};

export function ReportCampaignList({
  getReportsCampaignQuery,
  advertiserId,
  campaignId,
  // campaignName,
}: ReportCampaignListProps) {
  const { data, isLoading, isError, error } = getReportsCampaignQuery;

  return (
    <StyledTable aria-label="Tabela de Relatórios">
      <TableHead>
        <TableRow>
          <StyledHeaderTableCell sx={stickNameCell()}>
            Nome / Formato
          </StyledHeaderTableCell>
          <StyledHeaderTableCell>Plataforma</StyledHeaderTableCell>
          <StyledHeaderTableCell>Impressões</StyledHeaderTableCell>
          <StyledHeaderTableCell>Cliques</StyledHeaderTableCell>
          <StyledHeaderTableCell>CTR</StyledHeaderTableCell>
          <StyledHeaderTableCell>CPM</StyledHeaderTableCell>
          <StyledHeaderTableCell>CPC</StyledHeaderTableCell>
          {/* <StyledHeaderTableCell align="center">Download</StyledHeaderTableCell> */}
        </TableRow>
      </TableHead>

      <TableBody>
        {isLoading && (
          <TableRow>
            <TableCell component="th" scope="row" colSpan={8} align="center">
              <CircularProgress size={15} /> Carregando
            </TableCell>
          </TableRow>
        )}

        {!isLoading && !isError && data?.list?.length === 0 && (
          <TableRow>
            <TableCell colSpan={8} align="center">
              Sem resultados
            </TableCell>
          </TableRow>
        )}

        {isError && (
          <TableRow>
            <TableCell
              sx={{ color: theme => theme.palette.error.light }}
              colSpan={8}
              align="center"
            >
              <Typography>Ocorreu um erro: {error.name}</Typography>
              <Typography>{error.message}</Typography>
            </TableCell>
          </TableRow>
        )}

        {!isLoading &&
          !isError &&
          data?.list?.map(resume => {
            return (
              <ReportCampaignRow
                key={resume.name}
                resume={resume}
                advertiserId={advertiserId}
                campaignId={campaignId}
                // campaignName={campaignName}
              />
            );
          })}
      </TableBody>
    </StyledTable>
  );
}
