// import { useQuery } from '@tanstack/react-query';

import { serviceApi } from '~/features';
import { clearParams } from '~/shared';
import { ReportsFilterDto, ReportsFilterModelView } from '~/types';

export const GET_REPORTS_FILTER_QUERY_KEY = 'GET_REPORTS_FILTER_QUERY';

export function getReportsFilter(params: ReportsFilterDto) {
  const clearedParams = clearParams(params) as ReportsFilterDto;
  return serviceApi.get<ReportsFilterModelView>('/Report/Filter-List', {
    params: { ...clearedParams, segments: JSON.stringify(params.segments) },
  });
}

export function getReportsFilterTotal(params: ReportsFilterDto) {
  const clearedParams = clearParams(params) as ReportsFilterDto;
  return serviceApi.get<ReportsFilterModelView>('/Report/Filter-Total', {
    params: clearedParams,
  });
}
