import CredentialsProvider from 'next-auth/providers/credentials';

import { serviceApi } from '~/features/QueryClient/api';

import { authRefreshToken, authWithUsernameAndPassword } from '../helper';
import { AuthProviders, MartinsAuthError } from '../types';

export function MartinsProvider() {
  return CredentialsProvider({
    id: AuthProviders.MARTINS_CRED,
    name: 'Martins Credentials',
    type: 'credentials',
    credentials: {
      username: { label: 'Username', type: 'text' },
      password: { label: 'Password', type: 'password' },
    },
    authorize: async credentials => {
      if (!credentials?.password || !credentials.username) {
        throw new Error(MartinsAuthError[15]);
      }

      return authWithUsernameAndPassword(
        serviceApi,
        credentials.username,
        credentials.password
      );
    },
  });
}

export function RefreshTokenProvider() {
  return CredentialsProvider({
    id: AuthProviders.REFRESH_TOKEN,
    name: 'Refresh Token',
    type: 'credentials',
    credentials: {
      token: { label: 'Token', type: 'hidden' },
      serviceToken: { label: 'Service Token', type: 'hidden' },
      refreshToken: { label: 'Refresh Token', type: 'hidden' },
      registration: { label: 'Registration', type: 'hidden' },
    },
    authorize: async credentials => {
      if (!credentials?.refreshToken || !credentials?.token) {
        throw new Error(MartinsAuthError[15]);
      }
      return authRefreshToken(
        serviceApi,
        credentials.token,
        credentials.serviceToken,
        credentials.refreshToken,
        credentials.registration
      );
    },
  });
}
