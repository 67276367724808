export function capitalize(str?: string, lower = true) {
  if (!str) return '';
  return (lower ? str.toLowerCase() : str).replace(
    /(?:^|\s|["'([{])+\S/g,
    match => match.toUpperCase()
  );
}

export function uniqueId() {
  return Math.round(Date.now() * Math.random()).toString();
}
