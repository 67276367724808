import { type MouseEventHandler } from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, CircularProgress, Typography } from '@mui/material';

import { CampaignStatus } from '~/types';

import { StyledStatusBadgeButton } from './elements';

export type StatusBadgeProps = {
  status: `${CampaignStatus}`;
  withBorder?: boolean;
  withDetail?: boolean;
  withSelector?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  open?: boolean;
  id?: string;
  isLoading?: boolean;
};

export const CampaignStatusText = {
  [CampaignStatus.ACTIVE]: 'Ativa',
  [CampaignStatus.REVIEW]: 'Em aprovação',
  [CampaignStatus.ATTENTION]: 'Atenção', // temporarily disabled
  [CampaignStatus.DRAFT]: 'Rascunho',
  [CampaignStatus.INACTIVE]: 'Inativa',
  [CampaignStatus.PAUSED]: 'Pausada',
  [CampaignStatus.DONE]: 'Concluída',
};

export function StatusBadge({
  status,
  withBorder,
  withDetail,
  withSelector,
  onClick,
  open,
  id = 'campaign-status-badge',
  isLoading,
}: StatusBadgeProps) {
  return (
    <StyledStatusBadgeButton
      id={id}
      variant={status}
      withBorder={withBorder}
      withSelector={withSelector}
      disabled={!withSelector}
      onClick={onClick}
      {...(withSelector && { 'aria-haspopup': 'true' })}
      {...(withSelector &&
        open && {
          'aria-controls': 'campaign-status-select-menu',
          'aria-expanded': 'true',
        })}
    >
      {withDetail && (
        <Box
          sx={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: 'currentColor',
            display: 'inline-block',
            marginRight: '8px',
          }}
        />
      )}
      <Typography>{CampaignStatusText[status]}</Typography>
      {withSelector && (
        <Box sx={{ mr: -1, mt: 0.15 }}>
          {!isLoading && (
            <KeyboardArrowDown fontSize="small" sx={{ color: '#6E7191' }} />
          )}
          {isLoading && (
            <CircularProgress size={15} sx={{ color: '#6E7191', ml: 1 }} />
          )}
        </Box>
      )}
    </StyledStatusBadgeButton>
  );
}
