import { EditOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';

import { DeleteIcon } from '~/shared';

export type AdvertisersRowActionProps = {
  onDelete: () => void;
  onEdit: () => void;
};

export function AdvertisersRowAction({
  onDelete,
  onEdit,
}: AdvertisersRowActionProps) {
  return (
    <>
      <Button
        size="small"
        startIcon={<EditOutlined />}
        onClick={() => onEdit()}
        sx={theme => ({
          mr: '5px',
          textTransform: 'none',
          padding: theme.spacing(2),
          [theme.breakpoints.down('md')]: {
            mr: '0px',
            '.text': { display: 'none' },
            paddingRight: 0,
            '.MuiButton-startIcon': {
              marginRight: 0,
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(1),
            },
          },
        })}
      >
        <span className="text">Editar</span>
      </Button>
      <Button
        size="small"
        startIcon={<DeleteIcon size={18} />}
        onClick={() => onDelete()}
        sx={theme => ({
          color: '#E73933',
          textTransform: 'none',
          padding: theme.spacing(2),
          [theme.breakpoints.down('md')]: {
            '.text': { display: 'none' },
            paddingRight: 0,
            '.MuiButton-startIcon': {
              marginRight: 0,
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(1),
            },
          },
        })}
      >
        <span className="text">Excluir</span>
      </Button>
    </>
  );
}
