import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import sanitize from 'sanitize-html';

import { serviceApi } from '~/features';
import type { CreateAdvertiserDto, CreateAdvertiverModelView } from '~/types';

export const CREATE_ADVERTISER_MUTATION_KEY = 'CREATE_ADVERTISER_MUTATION';

async function createAdvertiser(body: CreateAdvertiserDto) {
  return serviceApi.post<CreateAdvertiverModelView>('/Anunciante', {
    name: sanitize(body.name),
  });
}

export function useCreateAdvertiser(
  onSuccess?: (
    data: AxiosResponse<CreateAdvertiverModelView, unknown>,
    variables: CreateAdvertiserDto,
    context: unknown
  ) => unknown,
  onError?: (
    error: Error,
    variables: CreateAdvertiserDto,
    context: unknown
  ) => unknown
) {
  return useMutation({
    mutationKey: [CREATE_ADVERTISER_MUTATION_KEY],
    mutationFn: async (body: CreateAdvertiserDto) => createAdvertiser(body),
    onSuccess,
    onError,
  });
}
