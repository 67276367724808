import { ArrowBack, ArrowForward } from '@mui/icons-material';
import {
  Pagination as DSPagination,
  type PaginationProps,
} from '@mui/material';

import { StyledPaginationBox, StyledPaginationNav } from './elements';

export function Pagination({
  count,
  color = 'primary',
  hideNextButton = true,
  hidePrevButton = true,
  nextPage,
  prevPage,
  disabled,
  page,
  ...props
}: Omit<PaginationProps, 'siblingCount'> & {
  nextPage?: () => void;
  prevPage?: () => void;
}) {
  return (
    <StyledPaginationBox>
      <StyledPaginationNav
        size="small"
        onClick={prevPage}
        startIcon={<ArrowBack />}
        disabled={disabled || page === 1}
      >
        Voltar
      </StyledPaginationNav>
      <DSPagination
        disabled={disabled}
        hideNextButton={hideNextButton}
        hidePrevButton={hidePrevButton}
        siblingCount={count}
        page={page}
        count={count}
        color={color}
        {...props}
      />
      <StyledPaginationNav
        size="small"
        onClick={nextPage}
        endIcon={<ArrowForward />}
        disabled={disabled || page === count}
      >
        Avançar
      </StyledPaginationNav>
    </StyledPaginationBox>
  );
}
