import { type NextAuthOptions } from 'next-auth';

import {
  jwtAuthHandler,
  sessionAuthHandler,
  singInAuthHandler,
} from './helper';
import {
  // AzureADProvider,
  MartinsProvider,
  // MockCredentialsProvider,
  RefreshTokenProvider,
} from './providers';

export const pagesConfig: NextAuthOptions['pages'] = {
  signIn: '/login',
  signOut: '/logout',
  error: '/login',
};

export const authOptions: NextAuthOptions = {
  secret: process.env.NEXT_AUTH_SECRET,
  providers: [
    // AzureADProvider(),
    // MockCredentialsProvider(),
    MartinsProvider(),
    RefreshTokenProvider(),
  ],
  callbacks: {
    signIn: singInAuthHandler,
    jwt: jwtAuthHandler,
    session: sessionAuthHandler,
  },
  pages: pagesConfig,
};
