'use client';

import { useState } from 'react';
import Image from 'next/image';
import { Box, Button } from '@mui/material';

import { Dialog } from '~/shared';

import { UseUpdateCampaignReturn } from '../hooks';

export function EditImageModal({
  campaignId,
  bannerKey,
  imageUrl,
  onClose,
  bannerWidth,
  bannerHeight,
  updateCampaignQuery,
  bannerType,
}: {
  campaignId: string;
  bannerKey: string;
  imageUrl?: string;
  onClose?: () => void;
  bannerWidth: number;
  bannerHeight: number;
  updateCampaignQuery: UseUpdateCampaignReturn;
  bannerType: 'web' | 'app';
}) {
  const [picture, setPicture] = useState<{
    img: string;
    type: string;
    originalExtension: string;
    file: File;
  } | null>(null);

  async function handleImagePreview(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    if (!event.target.files) {
      return;
    }

    const file = event.target.files[0];
    const img = URL.createObjectURL(file);

    try {
      const result = await fetch(img);
      const blob = await result.blob();
      let originalExtension = file.name.split('.').pop() ?? 'jpg';
      if ('name' in blob && typeof blob.name === 'string') {
        originalExtension = blob.name.split('.').pop() ?? 'jpg';
      }
      setPicture({ img, type: blob.type, originalExtension, file });
    } catch (err) {
      console.error({ err });
    }
  }

  function handleClose() {
    if (updateCampaignQuery.isPending) return;
    setPicture(null);
    onClose?.();
  }

  async function handleUploadImage() {
    if (!picture?.file) return;

    const dataUrl = await new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(picture.file);
    });

    if (!dataUrl) {
      return;
    }

    updateCampaignQuery.mutate(
      {
        id: campaignId,
        [bannerType === 'app' ? 'appBanners' : 'webBanners']: [
          {
            key: bannerKey,
            image: dataUrl,
          },
        ],
      },
      { onSuccess: handleClose }
    );
  }

  return (
    <Dialog
      maxWidth={picture ? 'lg' : 'sm'}
      onClose={handleClose}
      title={imageUrl ? 'Alterar imagem' : 'Adicionar imagem'}
      open
    >
      {!picture && imageUrl && (
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: `${bannerHeight}px`,
            maxWidth: `${bannerWidth}px`,
            margin: '0 auto',
            overflowX: 'auto',
            overflowY: 'hidden',
          }}
        >
          <Image
            alt="Imagem do banner"
            src={imageUrl}
            width={bannerWidth}
            height={bannerHeight}
          />
        </Box>
      )}

      {picture === null && (
        <Box sx={{ p: 2 }}>
          <Button
            variant="contained"
            component="label"
            fullWidth
            onClick={() => {}}
            sx={{ px: 3, py: 2 }}
          >
            Selecionar Imagem
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={handleImagePreview}
            />
          </Button>
        </Box>
      )}

      {picture && (
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: `${bannerHeight}px`,
            maxWidth: `${bannerWidth}px`,
            maxHeight: `${bannerHeight}px`,
            margin: '0 auto',
            overflow: 'hidden',
          }}
        >
          <Image
            alt="Preview da imagem"
            src={picture.img}
            width={bannerWidth}
            height={bannerHeight}
            style={{ objectFit: 'contain' }}
          />
        </Box>
      )}

      {picture && (
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            maxWidth: 'sm',
            m: '0 auto',
            mt: '150px',
          }}
        >
          <Button
            disabled={updateCampaignQuery.isPending}
            sx={{ px: 3, py: 2 }}
            size="small"
            fullWidth
            variant="outlined"
            onClick={() => handleClose()}
          >
            Cancelar
          </Button>

          <Button
            disabled={updateCampaignQuery.isPending}
            sx={{ px: 3, py: 2 }}
            variant="contained"
            size="small"
            fullWidth
            onClick={() => handleUploadImage()}
          >
            Salvar Imagem
          </Button>
        </Box>
      )}
    </Dialog>
  );
}
