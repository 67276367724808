import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

type BreadcrumbsPaths = { label: string; path: string }[] | null;

interface BreadcrumbsContextProps {
  paths: BreadcrumbsPaths;
  setPaths: Dispatch<SetStateAction<BreadcrumbsPaths>>;
}

interface BreadcrumbsProviderProps {
  children: React.ReactNode;
}

const BreadcrumbsContext = createContext<BreadcrumbsContextProps>({
  paths: null,
  setPaths: (prevState: SetStateAction<BreadcrumbsPaths>) => prevState,
});

function BreadcrumbsProvider({
  children,
}: BreadcrumbsProviderProps): JSX.Element {
  const [paths, setPaths] = useState<BreadcrumbsPaths>(null);

  const value = useMemo(() => ({ paths, setPaths }), [paths, setPaths]);

  return (
    <BreadcrumbsContext.Provider value={value}>
      {children}
    </BreadcrumbsContext.Provider>
  );
}

const BreadcrumbsConsumer = BreadcrumbsContext.Consumer;

const useBreadcrumbs = () => useContext(BreadcrumbsContext);

export { BreadcrumbsConsumer, BreadcrumbsProvider, useBreadcrumbs };
